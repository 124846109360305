'use strict'

/* in webpack.config.js kan je aangeven welke .js broncode wordt gebruikt, welke html template wordt gebruikt (index.html in de directory waar ook de broncode staat), hoe de compiled bestanden moeten gaan heten en waar het compiled bestand geplaatst moet worden
in package.json kan je zien wat de commandos doen
open de terminal vanaf de plek van de broncode, sudo -s en dan npm start(dit doet build en start een static server op die niet echt nodig is, maar zo zie je wel dat het compile proces goed is verlopen) 
dit index.js bestand gebruikt ook aangepaste stukken in forms.js en de gebruikte html template index.html verder is er iets in js-ipfs-master/src/core/components/libp2p.js aangepast voor het inladen van de peerID (inmiddels niet meer nodig)

In het resultaat van het compilen (directory:dist IPFSInterestnet): de index.html,      kan je het deel van de code (vanaf <style type)      naar IPFS_InterestNet.html overbrengen (onder de lijn)
IPFS_InterestNet.html is een aangepaste versie van de ge-publish'te html uit animate, het tes6.js file is de in animate ge-publishte javascript die gebruikt wordt (alle grafische delen gecompiled)  */

const IPFS = require('ipfs')
const {
  dragDrop,
  log,
  bufferToArrayBuffer
} = require('./utils')
const {
  updateTree
} = require('./filetree')
const {
  mvForm,
  mkdirForm,
  rmForm,
  cpForm,
  statForm,
  readForm,
  hideForms,
  activ_P1,
  activ_P2,
  activ_P3,
  activ_P4,
  activ_P5,
  activ_P6,
  activ_P7,
  activ_P8,
  activ_P9,
  activ_P10,
  activ_P11,
  activ_P12,
  activ_P12a,
  activ_P13,
  activ_P14,
  activ_P15,
  activ_P
} = require('./forms')
const mime = require('mime-sniffer')
//const wie = require('./poging')



document.addEventListener('DOMContentLoaded', async () => {
  const ipfs = await IPFS.create({
    repo: `ipfs_InterestNet`

  })
  hideForms()


  // Allow adding files to IPFS via drag and drop
  dragDrop(async (files) => {
    /* eslint-disable-next-line no-alert */
    const destinationDirectory = prompt(`Dropped ${files.length} file${files.length > 1 ? 's' : ''}, please enter a directory to store them in`, '/')

    if (!destinationDirectory || !`${destinationDirectory}`.trim()) {
      return
    }

    await Promise.all(
      files.map(file => {
        const path = `${destinationDirectory}/${file.name}`.replace(/\/\/+/g, '/')
        log(`ipfs.files.write('${path}', <File>, { create: true, parents: true })`)
        log(files.name)

 //nog doen:plaatje schalen en goede pad voor plaatje
        return ipfs.files.write(path, file, { //de Bitmap1 opslaan als plaatje in bv /Bitmaps/RP5149-2342-2.jpg (voor gebruik in een website)
          create: true,
          parents: true
        })
          .then((stats) => {
            document.getElementById('form-read-P8param11').value=path  })
          .catch(error => log(error))
      })
    )
            // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }

        const path =(getValue('form-read-P8param11'))
        const Wegschrijf = await ipfs.files.stat(path)
        const Wegschrijfhash = (Wegschrijf.cid.toString())
        log(Wegschrijfhash)
        document.getElementById('form-read-P8param11').value=Wegschrijfhash
        wegschrijf_P8() //automatisch starten om de hash ervan op te slaan in ContentVariabelen

    updateTree(ipfs)


  })



  mkdirForm(async (path, parents, format, hashAlg, flush) => {
    log(`ipfs.files.mkdir('${path}', ${JSON.stringify({
      parents,
      format,
      hashAlg,
      flush
    }, null, 2)})`)

    await ipfs.files.mkdir(path, {
      parents,
      format,
      hashAlg,
      flush
    })
      .catch(error => log(error))

    updateTree(ipfs)
  })

  mvForm(async (paths, destination, parents, format, hashAlg, flush) => {
    log(`ipfs.files.mv(${paths.map(path => `'${path}'`).join(', ')}, ${JSON.stringify({
      parents,
      format,
      hashAlg,
      flush
    }, null, 2)})`)

    await ipfs.files.mv.apply(null, paths.concat(destination, {
      parents,
      format,
      hashAlg,
      flush
    }))
      .catch(error => log(error))

    updateTree(ipfs)
  })

  rmForm(async (paths, recursive) => {
    log(`ipfs.files.rm(${paths.map(path => `'${path}'`).join(', ')}, ${JSON.stringify({
      recursive
    }, null, 2)})`)

    await ipfs.files.rm.apply(null, paths.concat({
      recursive
    }))
      .catch(error => log(error))

    updateTree(ipfs)

  })

  cpForm(async (paths, destination, parents, format, hashAlg, flush) => {
    log(`ipfs.files.cp(${paths.map(path => `'${path}'`).join(', ')}, '${destination}', ${JSON.stringify({
      parents,
      format,
      hashAlg,
      flush
    }, null, 2)})`)

    await ipfs.files.cp.apply(null, paths.concat(destination, {
      parents,
      format,
      hashAlg,
      flush
    }))
      .catch(error => log(error))

    updateTree(ipfs)
  })

  statForm(async (path, hash, size, withLocal) => {
    log(`ipfs.files.stat('${path}', ${JSON.stringify({
      hash,
      size,
      withLocal
    }, null, 2)})`)

    await ipfs.files.stat(path, {
      hash,
      size,
      withLocal
    })
      .then((stats) => log(stats+stats.cid))// stat.cid erbij gezet, lukt alleen zo, gek
      .catch(error => log(error))
  })


readForm(async (path, offset, length, timeout) => {
    log(`ipfs.files.read('${path}', ${JSON.stringify({
      offset,
      length
    }, null, 2)})`)

const chunks = []

console.log(path+' raw content:')

const encoder = new TextEncoder()
const decoder = new TextDecoder()

//Testing to (first put it on the network and then) get the content back
const { cid } = await ipfs.add(encoder.encode('This is the content of an added test file'))
for await (const chunk of ipfs.cat(cid)) {
  log(decoder.decode(chunk)) 
}
// end of test: prints 'This is the content of an added test file'

for await (const chunk of ipfs.files.read(path, {
      offset,
      length,
      timeout
    }))
{
console.info(decoder.decode(chunk))
//const data = (decoder.decode(chunk))//voor txt inhoud is dit nodig
const data = bufferToArrayBuffer(chunk)
console.info(data)
const mimeType = 'image/jpeg'
const file = new Blob([data], {
            type: mimeType
          })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
}

  })



    const ident = await ipfs.id()   //informatie over de IPFS node binnen halen (hiervoor is die aanpassing niet nodig)
 //   log(ident.id)
 //   log(ident.publicKey)
 //   log(ident.addresses)
    log(ident.agentVersion)
 //   log(ident.protocolVersion)
    document.getElementById('form-read-P2param1').value=ident.id

    log('IPFS node is connected, the InterestNet repo is identified by PeerId '+ident.id)
    exportRoot.play();
    log(' ')




//Interestnet opzoek parameter binnenhalen en opdrachtactivaties

    activ_P1(async (P1param1, P1param2, P1param3) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P1 wordt uitgevoerd met parameter: "+P1param1)
      opdrachtP1()
    })

    activ_P2(async (P2param1, P2param2, P2param3, P2param4, P2param5, P2param6, P2param7, P2param8, P2param9) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P2 wordt uitgevoerd met parameter: "+ident.id)
      opdrachtP2(P2param1,P2param2,P2param3,P2param4,P2param5,P2param6,P2param7,P2param8,P2param9)
    })

    activ_P3(async (P2param1, P3param2, P3param3) => {     //peerID van P2 gebruikt (ook in form.js)   //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden // nu volgensmij direct met ident.id in index.js
      log("IPFS opdracht-P3 wordt uitgevoerd met parameter: "+ident.id)
      opdrachtP3(P2param1,P3param2,P3param3)            //peerID van P2 gebruikt (ook in form.js)  // nu volgensmij direct met ident.id in index.js
    })

    activ_P4(async (P4param1, P4param2, P4param3, P4param4, P4param5, P4param6, P4param7, P4param8, P4param9, P4param10, P4param11) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P4 wordt uitgevoerd met parameter: "+P4param1)
      opdrachtP4(P4param1,P4param2,P4param3,P4param4,P4param5,P4param6,P4param7,P4param8,P4param9,P4param10,P4param11)
    })

    activ_P5(async (Pparam1, Pparam2, Pparam3) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P5 wordt uitgevoerd met parameter: "+Pparam1)
      opdrachtP5(Pparam1,Pparam2,Pparam3)
    })

    activ_P6(async (Pparam1, Pparam2, Pparam3) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P6 wordt uitgevoerd met parameter: "+Pparam1)
      opdrachtP6(Pparam1,Pparam2,Pparam3)
    })

    activ_P7(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8,Pparam9,Pparam10,Pparam11,Pparam12,Pparam13,Pparam14,Pparam15,Pparam16) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P7 wordt uitgevoerd met parameter: DCode="+Pparam1+' en SheetCode='+Pparam2)
      opdrachtP7(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8,Pparam9,Pparam10,Pparam11,Pparam12,Pparam13,Pparam14,Pparam15,Pparam16)
    })

    activ_P8(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8,Pparam9,Pparam10,Pparam11,Pparam12,Pparam13,Pparam14,Pparam15,Pparam16,Pparam17) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P8 wordt uitgevoerd met parameter: DCode="+Pparam1+' en SheetCode='+Pparam2)
      opdrachtP8(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8,Pparam9,Pparam10,Pparam11,Pparam12,Pparam13,Pparam14,Pparam15,Pparam16,Pparam17)
    })

    activ_P9(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P9 wordt uitgevoerd met parameter: DCode="+Pparam1+' en SheetCode='+Pparam2)
      opdrachtP9(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8)
    })

    activ_P10(async (Pparam1, Pparam2, Pparam3) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P10 wordt uitgevoerd met parameter: DCode="+Pparam1+' en SheetCode='+Pparam2)
      opdrachtP10(Pparam1,Pparam2,Pparam3)
    })

    activ_P11(async (Pparam1, Pparam2, Pparam3, Pparam4, Pparam5) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P11 wordt uitgevoerd met parameter: SheetCode="+Pparam1+' en UTitle='+Pparam2+' RTitle='+Pparam3+' DTitle='+Pparam4+' LTitle='+Pparam5)
      opdrachtP11(Pparam1,Pparam2,Pparam3, Pparam4, Pparam5)
    })

    activ_P12(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P12 wordt uitgevoerd met parameter: DCode="+Pparam2+' en SheetCode='+Pparam1)
      opdrachtP12(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8)
    })

    activ_P13(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log('IPFS opdracht-P13 wordt uitgevoerd met parameter:   SheetCode='+Pparam1+' en DCodeA='+Pparam2+'LocationCodeA='+Pparam3+'DCodeB='+Pparam4+'LocationCodeB='+Pparam5)
      opdrachtP13(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8)
    })

    activ_P12a(async (Pparam1, Pparam2) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log('IPFS opdracht-P12a wordt uitgevoerd met parameter:   SheetCode='+Pparam1+' en DCode='+Pparam2)
      opdrachtP12a(Pparam1,Pparam2)
    })

    activ_P14(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log('IPFS opdracht-P14 wordt uitgevoerd met parameter:   SheetCode='+Pparam1+' en ULocationCode='+Pparam2+'RLocationCode='+Pparam3+'DLocationCode='+Pparam4+'LLocationCode='+Pparam5)
      opdrachtP14(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8)
    })

    activ_P15(async (Pparam1, Pparam2, Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log('IPFS opdracht-P15 wordt uitgevoerd met parameter:   SheetCode='+Pparam1+' en UTPLocationCode='+Pparam2+'RTPLocationCode='+Pparam3+'DTPLocationCode='+Pparam4+'LTPLocationCode='+Pparam5)
      opdrachtP15(Pparam1,Pparam2,Pparam3,Pparam4,Pparam5,Pparam6,Pparam7,Pparam8)
    })

    activ_P(async (Pparam1, Pparam2, Pparam3) => {        //IPFS opdracht wordt hier met een async functie geactiveerd vanuit forms.js    de functienaam moet dus ook met require en module.exports overgebracht worden
      log("IPFS opdracht-P wordt uitgevoerd met parameter: "+Pparam1)
      opdrachtP(Pparam1,Pparam2,Pparam3)
    })





  updateTree(ipfs)

  //InterestNet ___________________________Test_________________________________________________________

              const TestHandmatig = () => {  //handmatig een IPFS opdracht uitvoeren met button
              const button2 = document.getElementById('button-show2')
              button2.onclick = doeTest()
              }

              const doeTest = () => {
                return () => {
                  opdrachtP1()
                }
              }
              TestHandmatig(async () => {}) //dit deel blijkt nodig om de functie pas na de muisklik te laten activeren


    async function opdrachtP1() {
      try {
        await ipfs.files.mkdir('/0000', {create: true, parents: true})
        await ipfs.files.mkdir('/11', {create: true, parents: true})
        await ipfs.files.mkdir('/12', {create: true, parents: true})
        await ipfs.files.mkdir('/13', {create: true, parents: true})
        await ipfs.files.mkdir('/14', {create: true, parents: true})
        await ipfs.files.mkdir('/21', {create: true, parents: true})
        await ipfs.files.mkdir('/22', {create: true, parents: true})
        await ipfs.files.mkdir('/23', {create: true, parents: true})
        await ipfs.files.mkdir('/24', {create: true, parents: true})
        await ipfs.files.mkdir('/31', {create: true, parents: true})

        await ipfs.files.write('/SheetCode.txt', Buffer.from('RP514'), {create: true, parents: true})
        await ipfs.files.write('/11/DCode.txt', Buffer.from('bafyreigsccjrxlioppkkzv27se4gxh2aygbxfnsobkaxxqiuni544uk66a'), {create: true, parents: true})
        await ipfs.files.write('/12/DCode.txt', Buffer.from('minimalwindow=1&check=1'), {create: true, parents: true})
        const stats = await ipfs.files.stat('/')
        log('structuur is aangemaakt, hash van de root is: '+(stats.cid))
          const stream = ipfs.files.readReadableStream('/11/DCode.txt')
          stream.on('data', (buf) => log(buf.toString('utf8')))

        updateTree(ipfs)

        } catch (err) {
            log ('mkdir is mislukt')
          }
        log ('mkdir is klaar')
    }
  
    //Interestnet _______________________IpVisitorVariabelenWegschrijf _____________________________________________

    async function opdrachtP2(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11) {
      //console.log(wie("qwwerew")) //test om functie uit poging.js te importeren, staat ook iets bovenin bij require
        const root = ("/IpVisitorVariabelen")
        const padP2param1 =(root+"/peerID"+ident.id)
        const padP2param2 =(root+"/peerID"+ident.id+"/SheetCode.txt") //als er al eentje met de zelfde naam bestaat wordt de waarde toegevoegd zonder dat het wordt overschreven waar er iets stond (het wordt er als het ware onder door geschreven)
        const padP2param3 =(root+"/peerID"+ident.id+"/Country.txt")
        const padP2param4 =(root+"/peerID"+ident.id+"/Interest.txt")
        const padP2param5 =(root+"/peerID"+ident.id+"/ChatMessage.txt")
        const padP2param6 =(root+"/peerID"+ident.id+"/MouseX.txt")
        const padP2param7 =(root+"/peerID"+ident.id+"/AutoEnter.txt")
        const padP2param8 =(root+"/peerID"+ident.id+"/AutoLogin.txt")
        const padP2param9 =(root+"/peerID"+ident.id+"/TimeCode.txt")
      try {
        await ipfs.files.mkdir(padP2param1, {create: true, parents: true})
        await ipfs.files.write(padP2param2, Buffer.from(param2), {create: true, parents: true})
        await ipfs.files.write(padP2param3, Buffer.from(param3), {create: true, parents: true})
        await ipfs.files.write(padP2param4, Buffer.from(param3), {create: true, parents: true})
        await ipfs.files.write(padP2param5, Buffer.from(param5), {create: true, parents: true})
        await ipfs.files.write(padP2param6, Buffer.from(param6), {create: true, parents: true})
        await ipfs.files.write(padP2param7, Buffer.from(param7), {create: true, parents: true})
        await ipfs.files.write(padP2param8, Buffer.from(param8), {create: true, parents: true})
        await ipfs.files.write(padP2param9, Buffer.from(param9), {create: true, parents: true})
        const stats = await ipfs.files.stat('/IpVisitorVariabelen')
        var NhashIpVisitorVariabelen = (stats.cid)


        // IPNS opslaan:
        const addr = ('/ipfs/'+(NhashIpVisitorVariabelen))
        const res = await ipfs.name.publish(addr) 
            // You now receive a res which contains two fields:  - name: the name under which the content was published. - value: the "real" address to which Name points.
            log(`vernieude IpVisitorVariabelen-ipns/${res.name} naar: ${res.value}`)
        // IPNS address opzoeken om te kunnen openen:
        const addr2 = res.name
        for await (const name of ipfs.name.resolve(addr2)) {
        log("resolve geeft: "+name)
        }



        // MFS updaten IpVisitorVariabelen.txt
        var opslaan = (''+(NhashIpVisitorVariabelen))  //nodig want hij herkent NhashIpVisitorVariabelen niet direct op een of andere vreemde manier
        await ipfs.files.write("/IpVisitorVariabelen.txt", Buffer.from(opslaan), {create: true, parents: true})
        log('bestandstructuur IpVisitorVariabelen is aangemaakt, hash van de root is: '+(stats.cid)+' ook in MFS opgeslagen als IpVisitorVariabelen.txt')

        // Orbitdb wegschrijven



        updateTree(ipfs)



        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P2 IpVisitorVariabelenWegschrijf')
          }
          exportRoot.play();
          log ('__P2 IpVisitorVariabelenWegschrijf klaar (/IpVisitorVariabelen/peerId/xxx zonder inputparam weggeschreven )____')
    }

  //Interestnet _____________________________IpVisitorVariabelenOphaal (can be a user)__________________________________

    async function opdrachtP3(param1,param2,param3) {
        const root = ("/IpVisitorVariabelen"+"/peerID"+ident.id)  //IPNS QmNb5MB3PdXzGaP3CMEV1Si4Tkkgb3rHqmqf84WspJs9Tf is de peerId van deze ipfs node > dit moet van de InterestNet-node worden

      try {
 


const encoder = new TextEncoder()
const decoder = new TextDecoder()

//Testing to (first put it on the network and then) get the content back
const { cid } = await ipfs.add(encoder.encode('This is the content of the added test file'))
for await (const chunk of ipfs.cat(cid)) {
  console.log(decoder.decode(chunk))//string met inhoud van ipfs.cat
  console.log (cid)                 //de hele data (resultaat van ipfs.add)
  console.log (cid.string)          //alleen de hash er van
}

//cat Hello, InterestNet!
var {chunkx} = await ipfs.cat('QmNSN23LCx1XNitosf8wgS4FhcpFMeN9FAHLAMnXDCNyQn') //dit deel lukt niet om weg te krijgen zonder dat er een error komt
for await (const chunk of ipfs.cat('QmNSN23LCx1XNitosf8wgS4FhcpFMeN9FAHLAMnXDCNyQn')) {
  log(decoder.decode(chunk)+' '+root) 
}


//peerId in een dom target te zetten

document.getElementById('dom-target27').value=ident.id

//uitlezen en in dom targets zetten
for await (const chunk of ipfs.files.read(root+'/SheetCode.txt')) {
const data = (decoder.decode(chunk))
log("SheetCode.txt: "+data)
document.getElementById('dom-target10').value=data
}
for await (const chunk of ipfs.files.read(root+'/Country.txt')) {
const data = (decoder.decode(chunk))
log("Country.txt: "+data)
document.getElementById('dom-target11').value=data
}
for await (const chunk of ipfs.files.read(root+'/Interest.txt')) {
const data = (decoder.decode(chunk))
log("Interest.txt: "+data)
document.getElementById('dom-target12').value=data
}
for await (const chunk of ipfs.files.read(root+'/ChatMessage.txt')) {
const data = (decoder.decode(chunk))
log("ChatMessage.txt: "+data)
document.getElementById('dom-target13').value=data
}
for await (const chunk of ipfs.files.read(root+'/MouseX.txt')) {
const data = (decoder.decode(chunk))
log("MouseX.txt: "+data)
document.getElementById('dom-target14').value=data
}
for await (const chunk of ipfs.files.read(root+'/AutoEnter.txt')) {
const data = (decoder.decode(chunk))
log("AutoEnter.txt: "+data)
document.getElementById('dom-target15').value=data
}
for await (const chunk of ipfs.files.read(root+'/AutoLogin.txt')) {
const data = (decoder.decode(chunk))
log("AutoLogin.txt: "+data)
document.getElementById('dom-target16').value=data
}
for await (const chunk of ipfs.files.read(root+'/TimeCode.txt')) {
const data = (decoder.decode(chunk))
log("TimeCode.txt: "+data)
document.getElementById('dom-target17').value=data
log('____P3 IpVisitorVariabelenOphaal deel I klaar (/IpVisitorVariabelen/peerId/xxx zonder inputparam opgehaald )___')
}




          const SheetCode = document.getElementById('dom-target10').value
          const root2 = ("/IpUserVariabelen"+"/"+SheetCode)
          log(root2)




                  for await (const chunk of ipfs.files.read(root2+'/PassWord.txt')) {
                  const data = (decoder.decode(chunk))
                  log("PassWord.txt: "+data)
                  document.getElementById('dom-target18').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/RecentUser.txt')) {
                  const data = (decoder.decode(chunk))
                  log("RecentUser.txt: "+data)
                  document.getElementById('dom-target19').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/NewestDCode.txt')) {
                  const data = (decoder.decode(chunk))
                  log("NewestDCode.txt: "+data)
                  document.getElementById('dom-target20').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Fullscreen.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Fullscreen.txt: "+data)
                  document.getElementById('dom-target21').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Mailaddress.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Mailaddress.txt: "+data)
                  document.getElementById('dom-target22').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Name.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Name.txt: "+data)
                  document.getElementById('dom-target23').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Continent.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Continent.txt: "+data)
                  document.getElementById('dom-target24').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/TotalSheets.txt')) {
                  const data = (decoder.decode(chunk))
                  log("TotalSheets.txt: "+data)
                  document.getElementById('dom-target25').value=data
                  }
                  for await (const chunk of ipfs.files.read(root2+'/MinimalView.txt')) {
                  const data = (decoder.decode(chunk))
                  log("MinimalView.txt: "+data)
                  document.getElementById('dom-target26').value=data
                  log('__P3 IpVisitorVariabelenOphaal deel II klaar (met sheetcode uit deel I /IpUserVariabelen/SheetCode/xxx)__')
                  }
                 //de overige dom-targets worden weggeschreven via de if voor url inlezen (js formule in de html)

        updateTree(ipfs)



        } catch (err) {
            exportRoot.play();
            log ('SheetCode niet aanwezig... of read is deels mislukt: P3 IpVisitorVariabelenOphaal deel II')
          }
            exportRoot.play();
    }

 //Interestnet ____________________________IpUserVariabelenWegschrijf ______________________________________

    async function opdrachtP4(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11) {
      //console.log(wie("qwwerew")) //test om functie uit poging.js te importeren, staat ook iets bovenin bij require

          //lege dir object QmUNLLsPACCz1vLxQVkXqqLX5R1X345qqfHbsf67hvA3Nn gebruiken om de weg te schrijven waarden aan te linken (nog zonder naam)
          const hdir = await ipfs.object.new({template: 'unixfs-dir'})     //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
          const hashdir = (hdir.toString())
          log('hash van lege dir: '+hashdir)

          //waardes opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
          const encoder = new TextEncoder()
          const decoder = new TextDecoder()
          const addOptions = {  pin: true };

          var { cid } = await ipfs.add(encoder.encode(param2), addOptions)
          for await (const chunk of ipfs.cat(cid)) {
            const waarde2 = (decoder.decode(chunk))   //string met inhoud (via ipfs.cat)
            console.log (cid)                  //de hele data (resultaat van ipfs.add)
            const hashwaarde2 = (cid.string)          //alleen de hash er van (via ipfs.cat)
            const dirwaarde2 = await ipfs.object.patch.addLink(hashdir, {          //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
              name: 'PassWord', size: 10, multihash: (hashwaarde2)   })
            const hashdirwaarde2 = (dirwaarde2.string)
            log ('nieuw object met de waarde: '+waarde2+' hash: '+hashwaarde2)
            log ('in een dir gestopt: '+hashdirwaarde2)

              var { cid } = await ipfs.add(encoder.encode(param3), addOptions)
              for await (const chunk3 of ipfs.cat(cid)) {
                const waarde3 = (decoder.decode(chunk3))//string met inhoud (via ipfs.cat)
                console.log (cid)                 //de hele data (resultaat van ipfs.add)
                const hashwaarde3 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                const dirwaarde3 = await ipfs.object.patch.addLink(hashdirwaarde2, {          //link toevoegen aan vorig object
                  name: 'RecentUser', size: 10, multihash: (hashwaarde3)   })
                const hashdirwaarde3 = (dirwaarde3.string)
                log ('RecentUser= '+waarde3+' de hash van deze volgende dir: '+hashdirwaarde3)

                  var { cid } = await ipfs.add(encoder.encode(param4), addOptions)
                  for await (const chunk4 of ipfs.cat(cid)) {
                    const waarde4 = (decoder.decode(chunk4))//string met inhoud (via ipfs.cat)
                    console.log (cid)                 //de hele data (resultaat van ipfs.add)
                    const hashwaarde4 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                    const dirwaarde4 = await ipfs.object.patch.addLink(hashdirwaarde3, {          //link toevoegen aan vorig object
                      name: 'NewestDCode', size: 10, multihash: (hashwaarde4)   })
                    const hashdirwaarde4 = (dirwaarde4.string)
                    log ('NewestDCode= '+waarde4+' de hash van deze volgende dir: '+hashdirwaarde4)

                      var { cid } = await ipfs.add(encoder.encode(param5), addOptions)
                      for await (const chunk5 of ipfs.cat(cid)) {
                        const waarde5 = (decoder.decode(chunk5))//string met inhoud (via ipfs.cat)
                        console.log (cid)                 //de hele data (resultaat van ipfs.add)
                        const hashwaarde5 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                        const dirwaarde5 = await ipfs.object.patch.addLink(hashdirwaarde4, {          //link toevoegen aan vorig object
                          name: 'Fullscreen', size: 10, multihash: (hashwaarde5)   })
                        const hashdirwaarde5 = (dirwaarde5.string)
                        log ('Fullscreen= '+waarde5+' de hash van deze volgende dir: '+hashdirwaarde5)

                          var { cid } = await ipfs.add(encoder.encode(param6), addOptions)
                          for await (const chunk6 of ipfs.cat(cid)) {
                            const waarde6 = (decoder.decode(chunk6))//string met inhoud (via ipfs.cat)
                            console.log (cid)                 //de hele data (resultaat van ipfs.add)
                            const hashwaarde6 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                            const dirwaarde6 = await ipfs.object.patch.addLink(hashdirwaarde5, {          //link toevoegen aan vorig object
                              name: 'Mailaddress', size: 10, multihash: (hashwaarde6)   })
                            const hashdirwaarde6 = (dirwaarde6.string)
                            log ('Mailaddress= '+waarde6+' de hash van deze volgende dir: '+hashdirwaarde6)

                              var { cid } = await ipfs.add(encoder.encode(param7), addOptions)
                              for await (const chunk7 of ipfs.cat(cid)) {
                                const waarde7 = (decoder.decode(chunk7))//string met inhoud (via ipfs.cat)
                                console.log (cid)                 //de hele data (resultaat van ipfs.add)
                                const hashwaarde7 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                                const dirwaarde7 = await ipfs.object.patch.addLink(hashdirwaarde6, {          //link toevoegen aan vorig object
                                  name: 'Name', size: 10, multihash: (hashwaarde7)   })
                                const hashdirwaarde7 = (dirwaarde7.string)
                                log ('Name= '+waarde7+' de hash van deze volgende dir: '+hashdirwaarde7)

                                  var { cid } = await ipfs.add(encoder.encode(param8), addOptions)
                                  for await (const chunk8 of ipfs.cat(cid)) {
                                    const waarde8 = (decoder.decode(chunk8))//string met inhoud (via ipfs.cat)
                                    console.log (cid)                 //de hele data (resultaat van ipfs.add)
                                    const hashwaarde8 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                                    const dirwaarde8 = await ipfs.object.patch.addLink(hashdirwaarde7, {          //link toevoegen aan vorig object
                                      name: 'Continent', size: 10, multihash: (hashwaarde8)   })
                                    const hashdirwaarde8 = (dirwaarde8.string)
                                    log ('Continent= '+waarde8+' de hash van deze volgende dir: '+hashdirwaarde8)

                                      var { cid } = await ipfs.add(encoder.encode(param9), addOptions)
                                      for await (const chunk9 of ipfs.cat(cid)) {
                                        const waarde9 = (decoder.decode(chunk9))//string met inhoud (via ipfs.cat)
                                        console.log (cid)                 //de hele data (resultaat van ipfs.add)
                                        const hashwaarde9 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                                        const dirwaarde9 = await ipfs.object.patch.addLink(hashdirwaarde8, {          //link toevoegen aan vorig object
                                          name: 'TotalSheets', size: 10, multihash: (hashwaarde9)   })
                                        const hashdirwaarde9 = (dirwaarde9.string)
                                        log ('TotalSheets= '+waarde9+' de hash van deze volgende dir: '+hashdirwaarde9)

                                          var { cid } = await ipfs.add(encoder.encode(param10), addOptions)
                                          for await (const chunk10 of ipfs.cat(cid)) {
                                            const waarde10 = (decoder.decode(chunk10))//string met inhoud (via ipfs.cat)
                                            console.log (cid)                 //de hele data (resultaat van ipfs.add)
                                            const hashwaarde10 = (cid.string)          //alleen de hash er van (via ipfs.cat)
                                            const dirwaarde10 = await ipfs.object.patch.addLink(hashdirwaarde9, {          //link toevoegen aan vorig object
                                              name: 'MinimalView', size: 10, multihash: (hashwaarde10)   })
                                            const hashdirwaarde10 = (dirwaarde10.string)
                                            log ('MinimalView= '+waarde10+' de hash van deze volgende dir: '+hashdirwaarde10)

                                            const updated_roothash2 = await ipfs.object.patch.addLink(hashdir, {      //de bovenstaande waarden (die al in een leeg/naamloos object zijn geplaatst) met naam DCode linken aan een nieuw object
                                              name: param1, size: 10, multihash: (hashdirwaarde10)   })


                                            //IpUserVariabelen IPFS-hash ophalen, met path orbit-ipns-hash/sheetcode kunnen de waardes worden opgehaald 
                                            var NhashIpUserVariabelen = updated_roothash2.string
                                            log('IPLD-bestandstructuur opgeslagen met ipfs.object')
                                            log('link voor hashIpUserVariabelen '+updated_roothash2.string+' met /'+param1+'/PassWord erachter is het pad naar de opgeslagen waarden')
                                            //waarden zo ophalen:
                                            const testhash = (updated_roothash2.string+'/'+param1+'/PassWord')
                                            const {chunkx} = await ipfs.cat(testhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
                                            for await (const chunk of ipfs.cat(testhash)) {
                                              log('PassWord= '+decoder.decode(chunk)) }

                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }





        const root = ("/IpUserVariabelen/"+param1)
        const padP4param2 =(root+"/PassWord.txt")
        const padP4param3 =(root+"/RecentUser.txt")
        const padP4param4 =(root+"/NewestDCode.txt")
        const padP4param5 =(root+"/Fullscreen.txt")
        const padP4param6 =(root+"/Mailaddress.txt")
        const padP4param7 =(root+"/Name.txt")
        const padP4param8 =(root+"/Continent.txt")
        const padP4param9 =(root+"/TotalSheets.txt")
        const padP4param10 =(root+"/MinimalView.txt")
    try {
        await ipfs.files.mkdir(root, {create: true, parents: true})
        await ipfs.files.write(padP4param2, Buffer.from(param2), {create: true, parents: true})
        await ipfs.files.write(padP4param3, Buffer.from(param3), {create: true, parents: true})
        await ipfs.files.write(padP4param4, Buffer.from(param4), {create: true, parents: true})
        await ipfs.files.write(padP4param5, Buffer.from(param5), {create: true, parents: true})
        await ipfs.files.write(padP4param6, Buffer.from(param6), {create: true, parents: true})
        await ipfs.files.write(padP4param7, Buffer.from(param7), {create: true, parents: true})
        await ipfs.files.write(padP4param8, Buffer.from(param8), {create: true, parents: true})
        await ipfs.files.write(padP4param9, Buffer.from(param9), {create: true, parents: true})
        await ipfs.files.write(padP4param10, Buffer.from(param10), {create: true, parents: true})
        const stats = await ipfs.files.stat(root)
        log('waarden ook opgeslagen in MFS, pad: /IpUserVariabelen/'+param1)



        // MFS updaten IpVisitorVariabelen.txt
        var opslaan = NhashIpUserVariabelen 
        await ipfs.files.write("/IpUserVariabelen.txt", Buffer.from(opslaan), {create: true, parents: true})
        log('bestandstructuur IpUserVariabelen is aangemaakt, hash van de root is: '+(opslaan)+' ook in MFS opgeslagen als IpUserVariabelen.txt')

        // Orbitdb wegschrijven



        updateTree(ipfs)








        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P4 IpUserVariabelenWegschrijf')
          }
        exportRoot.play();
        log ('________P4 IpUserVariabelenWegschrijf klaar__________')
    }
    
 //Interestnet ____________________________IpUserVariabelenOphaal___________________________________


    async function opdrachtP5(param1,param2,param3) {

            const encoder = new TextEncoder()
            const decoder = new TextDecoder()

            // IpUserVariabelen.txt ophalen uit MFS
            for await (const chunk of ipfs.files.read('/IpUserVariabelen.txt')) {
            var hashIpUserVariabelen = (decoder.decode(chunk))
            log("hash uit MFS: "+hashIpUserVariabelen)
            }
            // IpUserVariabelen ophalen uit Orbitdb



            // waarden uit IPLD structuur ophalen
            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/PassWord')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param1waarde = decoder.decode(chunk)
            document.getElementById('dom-target10').value=param1waarde
            log('PassWord= '+param1waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/RecentUser')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param2waarde = decoder.decode(chunk)
            document.getElementById('dom-target11').value=param2waarde
            log('RecentUser= '+param2waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/NewestDCode')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param3waarde = decoder.decode(chunk)
            document.getElementById('dom-target12').value=param3waarde
            log('NewestDCode= '+param3waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/Fullscreen')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param4waarde = decoder.decode(chunk)
            document.getElementById('dom-target13').value=param4waarde
            log('Fullscreen= '+param4waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/Mailaddress')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param5waarde = decoder.decode(chunk)
            document.getElementById('dom-target14').value=param5waarde
            log('Mailaddress= '+param5waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/Name')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param6waarde = decoder.decode(chunk)
            document.getElementById('dom-target15').value=param6waarde
            log('Name= '+param6waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/Continent')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param7waarde = decoder.decode(chunk)
            document.getElementById('dom-target16').value=param7waarde
            log('Continent= '+param7waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/TotalSheets')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param8waarde = decoder.decode(chunk)
            document.getElementById('dom-target17').value=param8waarde
            log('TotalSheets= '+param8waarde) }

            var ophaalhash = (hashIpUserVariabelen+'/'+param1+'/MinimalView')
            var {chunkx} = await ipfs.cat(ophaalhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
            for await (const chunk of ipfs.cat(ophaalhash)) {
            const param9waarde = decoder.decode(chunk)
            document.getElementById('dom-target18').value=param9waarde
            log('MinimalView= '+param9waarde) }



            document.getElementById('dom-target19').value='xx'
            document.getElementById('dom-target20').value='xx'
            document.getElementById('dom-target21').value='xx'
            document.getElementById('dom-target22').value='xx'
            document.getElementById('dom-target23').value='xx'
            document.getElementById('dom-target24').value='xx'
            document.getElementById('dom-target25').value='xx'
            document.getElementById('dom-target26').value='xx'
            document.getElementById('dom-target27').value='xx'
            document.getElementById('dom-target28').value='xx'
            document.getElementById('dom-target29').value='xx'
            document.getElementById('dom-target30').value='xx'
            document.getElementById('dom-target31').value='xx'
            document.getElementById('dom-target32').value='xx'
            document.getElementById('dom-target33').value='xx'
            document.getElementById('dom-target34').value='xx'
            document.getElementById('dom-target35').value='xx'
            document.getElementById('dom-target36').value='xx'
            document.getElementById('dom-target37').value='xx'
            document.getElementById('dom-target38').value='xx'
            document.getElementById('dom-target39').value='xx'



            const root2 = ("/IpUserVariabelen"+"/"+param1)

      try {



                  // ook nog via MFS
                  for await (const chunk of ipfs.files.read(root2+'/PassWord.txt')) {
                  const data = (decoder.decode(chunk))
                  log("PassWord.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/RecentUser.txt')) {
                  const data = (decoder.decode(chunk))
                  log("RecentUser.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/NewestDCode.txt')) {
                  const data = (decoder.decode(chunk))
                  log("NewestDCode.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Fullscreen.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Fullscreen.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Mailaddress.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Mailaddress.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Name.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Name.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/Continent.txt')) {
                  const data = (decoder.decode(chunk))
                  log("Continent.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/TotalSheets.txt')) {
                  const data = (decoder.decode(chunk))
                  log("TotalSheets.txt: "+data)
                  }
                  for await (const chunk of ipfs.files.read(root2+'/MinimalView.txt')) {
                  const data = (decoder.decode(chunk))
                  log("MinimalView.txt: "+data)

            exportRoot.play()
            log('______P5 IpUserVariabelenOphaal klaar (uit /IpUserVariabelen/SheetCode/xxx)______')    }

        updateTree(ipfs)


        } catch (err) {
            exportRoot.play();
            log ('SheetCode bestaat niet in lijst... of read is deels mislukt: P5 IpUserVariabelenOphaal')
          }
    }

//Interestnet ____________________________LocationCodeOphaal___________________________________
//   uit nieuwe IPLD____bafyreihnusfnuhpf7z7r7h7kikkq2ii33d54prr3m4r43hhrlzxfxmmlo4

    async function opdrachtP6(param1,param2,param3) {


      try {


        //Dag get Hello, InterestNet!
        var mijnData = {    
          0: ['1', 'Hello,', 3, '4'],
          1: ['11', ' InterestNet from IPLD!', '13', '14']  }

        var merkle = await ipfs.dag.put(mijnData, { format: 'dag-cbor', hashAlg: 'sha2-256' })
        log(merkle.toString())
       
        var resultaat
        resultaat = await ipfs.dag.get(merkle + '/0/1')
        log(resultaat.value)
        resultaat = await ipfs.dag.get(merkle + '/1/1')
        log(resultaat.value)


        // waarden uit IPLD structuur ophalen
        const IPLDcid = ('bafyreibgraujr6touwxjyhjciubzxevlfk54pkyohi7a74prha7mqdefci')
        var uitIPLD
        uitIPLD = await ipfs.dag.get(IPLDcid + '/'+param1+'/0')
        log('voor LocationCode '+param1+' U='+(uitIPLD.value)+' R='+(uitIPLD.value+1)+' D='+(uitIPLD.value+2)+' L='+(uitIPLD.value+3) )



  //const myData1 = {
  //  naam: ['pats', 'zus'],
  //  spullen: ['iets'] }
  //const cid1 = await ipfs.dag.put(myData1, { format: 'dag-cbor', hashAlg: 'sha2-256' })
  //log(cid1.toString())

  //const myData = {    //LocationCode: U, R, D, L        LocationCodeInvuller
  //  name: 'LocationCodes',
  //  likes: [param1, 'icecream', 'steak'],
  //  dislikes: cid1 }
  //const cid2 = await ipfs.dag.put(myData, { format: 'dag-cbor', hashAlg: 'sha2-256' })
  //log(cid2.toString())

 // let result
 // result = await ipfs.dag.get(cid2, 'name')
 // log(result.value)
 // result = await ipfs.dag.get(cid2 + '/likes/0')
 // log(result.value)
 // result = await ipfs.dag.get(cid2 + '/dislikes/naam/0')
 // log(result.value)



  const myData1 = {
    naam: ['pats', 'zus'],
    spullen: ['iets'] }
  const cid1 = await ipfs.dag.put(myData1, { format: 'dag-cbor', hashAlg: 'sha2-256' })
  log(cid1.toString())



  const myData = {    
    0: ['1', 2, '3', '4'],
    1: ['11', '12', '13', '14']
   }
  const cid2 = await ipfs.dag.put(myData, { format: 'dag-cbor', hashAlg: 'sha2-256' })
  log(cid2.toString())

 
  let result
  result = await ipfs.dag.get(cid2 + '/0/1')
  log(result.value)
  result = await ipfs.dag.get(cid2 + '/1/1')
  log(result.value)


  exportRoot.play();

        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P6 LocationCodeOphaal')
          }
    }

//Interestnet ____________________________ContentVariabelenWegschrijf (text en titels)___________________________________

    async function opdrachtP7(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16) {

            const encoder = new TextEncoder()
            const decoder = new TextDecoder()

      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new({template: 'unixfs-dir'})        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (hashdir)  })
        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
        await ipfs.files.stat('/ContentVariabelen.txt')
          .then((stats) => { document.getElementById('form-read-P7param0').value=1  
            log('/ContentVariabelen.txt wordt geopend...')  })
          .catch(error => { document.getElementById('form-read-P7param0').value=0
            log('er wordt een nieuwe /ContentVariabelen.txt aangemaakt')  })

        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const f =(getValue('form-read-P7param0'))

        if (f == 0) { const ContentVariabelen = (updated_leeghash.toString())      
                      log(' vanuit een leeg object: '+ContentVariabelen) 
                      document.getElementById('form-read-P7param0').value=ContentVariabelen }
        if (f == 1) {for await (const chunk of ipfs.files.read('/ContentVariabelen.txt')) {
                               const ContentVariabelen = (decoder.decode(chunk))
                               log("ContentVariabelen.txt opgehaald: "+ContentVariabelen)
                               document.getElementById('form-read-P7param0').value=ContentVariabelen } }
                                
        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const ContentVariabelen =(getValue('form-read-P7param0'))
        

      

        //de directe (nested) hash ophalen    //param2=SheetCode en param1=DCode param3=LocationCode
        const Wegschrijf1 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/'+param2+'/'+param1+'/')
        const Wegschrijf1hash = (Wegschrijf1.cid)
        log('hash waar de links uit worden verwijderd: '+Wegschrijf1hash)
        
        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur--> zie onderin




        //bestaande links verwijderen
        const hashdirwaarde4leeg = await ipfs.object.patch.rmLink(Wegschrijf1hash, {          
          name: 'Title'   })
        const hashdirwaarde5leeg = await ipfs.object.patch.rmLink(hashdirwaarde4leeg, {     
          name: 'Titlenew'   })
        const hashdirwaarde6leeg = await ipfs.object.patch.rmLink(hashdirwaarde5leeg, {        
          name: 'Text1'   })
        const hashdirwaarde7leeg = await ipfs.object.patch.rmLink(hashdirwaarde6leeg, {       
          name: 'Text1new'   })
        const hashdirwaarde8leeg = await ipfs.object.patch.rmLink(hashdirwaarde7leeg, {          
          name: 'Text2'   })
        const hashdirwaarde9leeg = await ipfs.object.patch.rmLink(hashdirwaarde8leeg, {       
          name: 'Text2new'   })
        const hashdirwaarde10leeg = await ipfs.object.patch.rmLink(hashdirwaarde9leeg, {        
          name: 'Text3'   })
        const hashdirwaarde11leeg = await ipfs.object.patch.rmLink(hashdirwaarde10leeg, {          
          name: 'Text3new'   })
        const hashdirwaarde12leeg = await ipfs.object.patch.rmLink(hashdirwaarde11leeg, {         
          name: 'Text4'   })
        const hashdirwaarde13leeg = await ipfs.object.patch.rmLink(hashdirwaarde12leeg, {       
          name: 'Text4new'   })
        const hashdirwaarde14leeg = await ipfs.object.patch.rmLink(hashdirwaarde13leeg, {         
          name: 'OriginalSheetCode'   })        
        const hashdirwaarde15leeg = await ipfs.object.patch.rmLink(hashdirwaarde14leeg, {         
          name: 'OriginalDCode'   })
        const hashdirwaarde16leeg = await ipfs.object.patch.rmLink(hashdirwaarde15leeg, {       
          name: 'Teleport'   })

        log('hash waaraan wordt gelinkt: '+hashdirwaarde16leeg.toString())
        const Wegschrijf1hashleeg = hashdirwaarde16leeg

        //waarden opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
        const addOptions = {  pin: true };

        var { cid } = await ipfs.add(encoder.encode(param4), addOptions)
        for await (const chunk4 of ipfs.cat(cid)) {
        const hashwaarde4 = (cid.string)
        const hashdirwaarde4 = await ipfs.object.patch.addLink(Wegschrijf1hashleeg, {          //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
        name: 'Title', size: 10, multihash: (hashwaarde4)   }) 

            var { cid } = await ipfs.add(encoder.encode(param5), addOptions)
            for await (const chunk5 of ipfs.cat(cid)) {
            const hashwaarde5 = (cid.string)
            const hashdirwaarde5 = await ipfs.object.patch.addLink(hashdirwaarde4, {          //link toevoegen aan vorig object
            name: 'Titlenew', size: 10, multihash: (hashwaarde5)   }) 

                var { cid } = await ipfs.add(encoder.encode(param6), addOptions)
                for await (const chunk6 of ipfs.cat(cid)) {
                const hashwaarde6 = (cid.string)
                const hashdirwaarde6 = await ipfs.object.patch.addLink(hashdirwaarde5, {          //link toevoegen aan vorig object
                name: 'Text1', size: 10, multihash: (hashwaarde6)   }) 

                    var { cid } = await ipfs.add(encoder.encode(param7), addOptions)
                    for await (const chunk7 of ipfs.cat(cid)) {
                    const hashwaarde7 = (cid.string)
                    const hashdirwaarde7 = await ipfs.object.patch.addLink(hashdirwaarde6, {          //link toevoegen aan vorig object
                    name: 'Text1new', size: 10, multihash: (hashwaarde7)   }) 

                        var { cid } = await ipfs.add(encoder.encode(param8), addOptions)
                        for await (const chunk8 of ipfs.cat(cid)) {
                        const hashwaarde8 = (cid.string)
                        const hashdirwaarde8 = await ipfs.object.patch.addLink(hashdirwaarde7, {          //link toevoegen aan vorig object
                        name: 'Text2', size: 10, multihash: (hashwaarde8)   }) 

                            var { cid } = await ipfs.add(encoder.encode(param9), addOptions)
                            for await (const chunk9 of ipfs.cat(cid)) {
                            const hashwaarde9 = (cid.string)
                            const hashdirwaarde9 = await ipfs.object.patch.addLink(hashdirwaarde8, {          //link toevoegen aan vorig object
                            name: 'Text2new', size: 10, multihash: (hashwaarde9)   }) 

                                var { cid } = await ipfs.add(encoder.encode(param10), addOptions)
                                for await (const chunk10 of ipfs.cat(cid)) {
                                const hashwaarde10 = (cid.string)
                                const hashdirwaarde10 = await ipfs.object.patch.addLink(hashdirwaarde9, {          //link toevoegen aan vorig object
                                name: 'Text3', size: 10, multihash: (hashwaarde10)   }) 

                                    var { cid } = await ipfs.add(encoder.encode(param11), addOptions)
                                    for await (const chunk11 of ipfs.cat(cid)) {
                                    const hashwaarde11 = (cid.string)
                                    const hashdirwaarde11 = await ipfs.object.patch.addLink(hashdirwaarde10, {          //link toevoegen aan vorig object
                                    name: 'Text3new', size: 10, multihash: (hashwaarde11)   }) 

                                        var { cid } = await ipfs.add(encoder.encode(param12), addOptions)
                                        for await (const chunk12 of ipfs.cat(cid)) {
                                        const hashwaarde12 = (cid.string)
                                        const hashdirwaarde12 = await ipfs.object.patch.addLink(hashdirwaarde11, {          //link toevoegen aan vorig object
                                        name: 'Text4', size: 10, multihash: (hashwaarde12)   }) 

                                            var { cid } = await ipfs.add(encoder.encode(param13), addOptions)
                                            for await (const chunk13 of ipfs.cat(cid)) {
                                            const hashwaarde13 = (cid.string)
                                            const hashdirwaarde13 = await ipfs.object.patch.addLink(hashdirwaarde12, {          //link toevoegen aan vorig object
                                            name: 'Text4new', size: 10, multihash: (hashwaarde13)   }) 

                                                var { cid } = await ipfs.add(encoder.encode(param14), addOptions)
                                                for await (const chunk14 of ipfs.cat(cid)) {
                                                const hashwaarde14 = (cid.string)
                                                const hashdirwaarde14 = await ipfs.object.patch.addLink(hashdirwaarde13, {          //link toevoegen aan vorig object
                                                name: 'OriginalSheetCode', size: 10, multihash: (hashwaarde14)   }) 

                                                    var { cid } = await ipfs.add(encoder.encode(param15), addOptions)
                                                    for await (const chunk15 of ipfs.cat(cid)) {
                                                    const hashwaarde15 = (cid.string)
                                                    const hashdirwaarde15 = await ipfs.object.patch.addLink(hashdirwaarde14, {          //link toevoegen aan vorig object
                                                    name: 'OriginalDCode', size: 10, multihash: (hashwaarde15)   }) 

                                                        var { cid } = await ipfs.add(encoder.encode(param16), addOptions)
                                                        for await (const chunk16 of ipfs.cat(cid)) {
                                                        const hashwaarde16 = (cid.string)
                                                        const hashdirwaarde16 = await ipfs.object.patch.addLink(hashdirwaarde15, {          //link toevoegen aan vorig object
                                                        name: 'Teleport', size: 10, multihash: (hashwaarde16)   }) 
                                                        document.getElementById('form-read-P7param0').value=hashdirwaarde16.string
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const hashdirwaarde16 =(getValue('form-read-P7param0'))





        const root = ("/ContentVariabelen/"+param2+"/"+param1)  //param2=SheetCode en param1=DCode param3=LocationCode
        const padP7param4 =(root+"/Title")
        const padP7param5 =(root+"/Titlenew")
        const padP7param6 =(root+"/Text1")
        const padP7param7 =(root+"/Text1new")
        const padP7param8 =(root+"/Text2")
        const padP7param9 =(root+"/Text2new")
        const padP7param10 =(root+"/Text3")
        const padP7param11 =(root+"/Text3new")
        const padP7param12 =(root+"/Text4")
        const padP7param13 =(root+"/Text4new")
        const padP7param14 =(root+"/OriginalSheetCode")
        const padP7param15 =(root+"/OriginalDCode")
        const padP7param16 =(root+"/Teleport")

        await ipfs.files.mkdir(root, {create: true, parents: true})
        await ipfs.files.write(padP7param4, Buffer.from(param4), {create: true, parents: true})
        await ipfs.files.write(padP7param5, Buffer.from(param5), {create: true, parents: true})
        await ipfs.files.write(padP7param6, Buffer.from(param6), {create: true, parents: true})
        await ipfs.files.write(padP7param7, Buffer.from(param7), {create: true, parents: true})
        await ipfs.files.write(padP7param8, Buffer.from(param8), {create: true, parents: true})
        await ipfs.files.write(padP7param9, Buffer.from(param9), {create: true, parents: true})
        await ipfs.files.write(padP7param10, Buffer.from(param10), {create: true, parents: true})
        await ipfs.files.write(padP7param11, Buffer.from(param11), {create: true, parents: true})
        await ipfs.files.write(padP7param12, Buffer.from(param12), {create: true, parents: true})
        await ipfs.files.write(padP7param13, Buffer.from(param13), {create: true, parents: true})
        await ipfs.files.write(padP7param14, Buffer.from(param14), {create: true, parents: true})
        await ipfs.files.write(padP7param15, Buffer.from(param15), {create: true, parents: true})
        await ipfs.files.write(padP7param16, Buffer.from(param16), {create: true, parents: true})
        updateTree(ipfs)

        //de hash opzoeken van de MFS bestanden hierboven aangemaakt
        const sheetcodes_dirhash = await ipfs.files.stat('/ContentVariabelen/'+param2) //als link met de naam van de sheetcode  toe te voegen aan IPFS-object 
        const dcodes_dirhash = await ipfs.files.stat('/ContentVariabelen/'+param2+'/'+param1) //deze link met naam van de dcode wordt straks om toe te voegen aan het IPFS-object
        log('MFS-bestandstructuur is ook aangemaakt, hash van de MFS-dir met de sheetcodes is: '+(sheetcodes_dirhash.cid))







        //de directe (nested) hash ophalen en link weghalen
        const dir2 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/'+param2+'/')
        const dir2hash = (dir2.cid)
        const dir2hashleeg = await ipfs.object.patch.rmLink(dir2hash, {       
          name: param1   })
        const dir2hashleeghash = (dir2hashleeg.string)
        const dir3 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/')
        const dir3hash = (dir3.cid)
        const dir3hashleeg = await ipfs.object.patch.rmLink(dir3hash, {       
          name: param2   })
        const dir3hashleeghash = (dir3hashleeg.string)


        // 2x het bestaande IPFS-object updaten met nieuwe link
        const updated_roothash2 = await ipfs.object.patch.addLink(dir2hashleeghash, {    //DCode er opnieuw bij linken 
          name: param1,
          size: 10,
          multihash: (hashdirwaarde16)   })

        const updated_roothash = await ipfs.object.patch.addLink(dir3hashleeghash, {    //SheetCode er opnieuw bij linken 
          name: param2,
          size: 10,
          multihash: (updated_roothash2)   })

        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur
        log('bestandstructuur opgeslagen in ipfs.object')
        const ContentVariabelenhashN = (updated_roothash.string)
        log('nieuwe link voor ContentVariabelen '+ContentVariabelenhashN+' met /'+param2+'/'+param1+'/Title  erachter is het pad naar de opgeslagen waarden')
        await ipfs.files.write('/ContentVariabelen.txt', Buffer.from(ContentVariabelenhashN), {create: true, parents: true})
        updateTree(ipfs)



        //waardes zo ophalen:
        const testhash = (updated_roothash.string+'/'+param2+'/'+param1+'/Title')
        var {chunkx} = await ipfs.cat(testhash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(testhash)) {
        const testwaarde = decoder.decode(chunk)
        log('test ophalen Title= '+testwaarde)  }


// VOORBEELD: object aanmaken en ophalen
const obj = { //let op: moet volgens mij een object.new zijn       met data toe te voegen door een ipfs.add te maken      en die via object.patch.addLink toe te voegen, andes is het niet op te halen via een pad
  Data: Buffer.from('Some data'),
  Links: [] }
const objcid = await ipfs.object.put(obj)
console.log(objcid.toString())

const objdata = await ipfs.object.data(objcid)
console.log('opgehaald met object.data: '+objdata.toString())

const objlinks = await ipfs.object.links(objcid)
const hashes = objlinks.map((link) => link.Hash.toString())
console.log('opgehaald met object.links: '+hashes)

//data erbij
const hashnadata = await ipfs.object.patch.setData(objcid, Buffer.from('more data'))
console.log('geupdate met appendData: '+hashnadata)

const hashnadatadata = await ipfs.object.data(hashnadata)
console.log('de Data field is nu: '+hashnadatadata.toString())

//link erbij of overschrijven
const hashnaddlink = await ipfs.object.patch.addLink(objcid, {
  name: 'iets-i',
  size: 10,
  multihash: 'Qmayz4F4UzqcAMitTzU4zCSckDofvxstDuj3y7ajsLLEVs'   })
console.log(hashnaddlink+' is de node with the added link')


const objcid2 = hashnaddlink

const objlinks2 = await ipfs.object.links(objcid2)
const hashes2 = objlinks2.map((link) => link.Hash.toString())
console.log('links field is nu: '+hashes2)

const hashnaddlink2 = await ipfs.object.patch.addLink(objcid, {
  name: 'bericht',
  size: 10,
  multihash: 'QmNSN23LCx1XNitosf8wgS4FhcpFMeN9FAHLAMnXDCNyQn'   })
console.log(hashnaddlink2+' is de node with the added link2')

//link removen
const hashnarmlink = await ipfs.object.patch.rmLink(hashnaddlink, {
  name: 'iets-i'  })

const stats2 = await ipfs.object.stat(hashnaddlink, {timeout: '10s'})
console.log(stats2)

// waarde opslaan
const schrijf0 = await ipfs.add("Hello, InterestNet!")
console.log(schrijf0)



        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P7 ContentVariabelenWegschrijf')
          }
        exportRoot.play();
        log ('________P7 ContentVariabelenWegschrijf klaar__________')
    }


//Interestnet ____________________________ContentVariabelenWegschrijf2 (alle data, behalve text en titels)___________________________________


    async function opdrachtP8(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16,param17) {

            const encoder = new TextEncoder()
            const decoder = new TextDecoder()

      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new({template: 'unixfs-dir'})        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (hashdir)  })
        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
        await ipfs.files.stat('/ContentVariabelen.txt')
          .then((stats) => { document.getElementById('form-read-P8param0').value=1  
            log('/ContentVariabelen.txt wordt geopend...')  })
          .catch(error => { document.getElementById('form-read-P8param0').value=0
            log('er wordt een nieuwe /ContentVariabelen.txt aangemaakt')  })

        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const f =(getValue('form-read-P8param0'))

        if (f == 0) { const ContentVariabelen = (updated_leeghash.toString())      
                      log(' vanuit een leeg object: '+ContentVariabelen) 
                      document.getElementById('form-read-P8param0').value=ContentVariabelen }
        if (f == 1) {for await (const chunk of ipfs.files.read('/ContentVariabelen.txt')) {
                               const ContentVariabelen = (decoder.decode(chunk))
                               log("ContentVariabelen.txt opgehaald: "+ContentVariabelen)
                               document.getElementById('form-read-P8param0').value=ContentVariabelen } }
                                
        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const ContentVariabelen =(getValue('form-read-P8param0'))
        

      

        //de directe (nested) hash ophalen    //param2=SheetCode en param1=DCode param3=LocationCode
        const Wegschrijf1 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/'+param2+'/'+param1+'/')
        const Wegschrijf1hash = (Wegschrijf1.cid)
        log('hash waar de links uit worden verwijderd: '+Wegschrijf1hash)
        
        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur--> zie onderin




        //bestaande links verwijderen
        const hashdirwaarde4leeg = await ipfs.object.patch.rmLink(Wegschrijf1hash, {          
          name: 'TextType'   })
        const hashdirwaarde5leeg = await ipfs.object.patch.rmLink(hashdirwaarde4leeg, {     
          name: 'TextTime'   })
        const hashdirwaarde6leeg = await ipfs.object.patch.rmLink(hashdirwaarde5leeg, {        
          name: 'Textx'   })
        const hashdirwaarde7leeg = await ipfs.object.patch.rmLink(hashdirwaarde6leeg, {       
          name: 'Texty'   })
        const hashdirwaarde8leeg = await ipfs.object.patch.rmLink(hashdirwaarde7leeg, {          
          name: 'ImageType'   })
        const hashdirwaarde9leeg = await ipfs.object.patch.rmLink(hashdirwaarde8leeg, {       
          name: 'Imagex'   })
        const hashdirwaarde10leeg = await ipfs.object.patch.rmLink(hashdirwaarde9leeg, {        
          name: 'Imagey'   })
        var hashdirwaarde11leeg = await ipfs.object.patch.rmLink(hashdirwaarde10leeg, {          
          name: 'Bitmap1'   })
            //als P8param11 begint met 'blob' (dan dus geen nieuw plaatje gekozen) > de remove van Bitmap1 en Bitmap1H(in stap18>) wordt dan over geslagen
            var Bitmap1 = param11.slice(0,4)
            if (Bitmap1 == 'blob')  {
            var hashdirwaarde11leeg = hashdirwaarde10leeg } 
        const hashdirwaarde12leeg = await ipfs.object.patch.rmLink(hashdirwaarde11leeg, {         
          name: 'Bitmap2'   })
        const hashdirwaarde13leeg = await ipfs.object.patch.rmLink(hashdirwaarde12leeg, {       
          name: 'Bitmap3'   })
        const hashdirwaarde14leeg = await ipfs.object.patch.rmLink(hashdirwaarde13leeg, {         
          name: 'Bitmap4'   })        
        const hashdirwaarde15leeg = await ipfs.object.patch.rmLink(hashdirwaarde14leeg, {         
          name: 'BitmapTime'   })
        const hashdirwaarde16leeg = await ipfs.object.patch.rmLink(hashdirwaarde15leeg, {       
          name: 'VideoStart'   })
        const hashdirwaarde17leeg = await ipfs.object.patch.rmLink(hashdirwaarde16leeg, {       
          name: 'VideoStop'   })
        var hashdirwaarde18leeg = await ipfs.object.patch.rmLink(hashdirwaarde17leeg, {       
          name: 'Bitmap1H'   })
              //als P8param11 begint met 'blob' (dan dus geen nieuw plaatje gekozen) > de remove van Bitmap1(in stap10>) en Bitmap1H wordt dan over geslagen
              if (Bitmap1 == 'blob')  {
              var hashdirwaarde18leeg = hashdirwaarde17leeg  }




        log('hash waaraan wordt gelinkt: '+hashdirwaarde18leeg.toString())
        const Wegschrijf1hashleeg = hashdirwaarde18leeg

        //waarden opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
        const addOptions = {  pin: true };

        var { cid } = await ipfs.add(encoder.encode(param4), addOptions)
        for await (const chunk4 of ipfs.cat(cid)) {
        const hashwaarde4 = (cid.string)
        const hashdirwaarde4 = await ipfs.object.patch.addLink(Wegschrijf1hashleeg, {          //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
        name: 'TextType', size: 10, multihash: (hashwaarde4)   }) 

            var { cid } = await ipfs.add(encoder.encode(param5), addOptions)
            for await (const chunk5 of ipfs.cat(cid)) {
            const hashwaarde5 = (cid.string)
            const hashdirwaarde5 = await ipfs.object.patch.addLink(hashdirwaarde4, {          //link toevoegen aan vorig object
            name: 'TextTime', size: 10, multihash: (hashwaarde5)   }) 

                var { cid } = await ipfs.add(encoder.encode(param6), addOptions)
                for await (const chunk6 of ipfs.cat(cid)) {
                const hashwaarde6 = (cid.string)
                const hashdirwaarde6 = await ipfs.object.patch.addLink(hashdirwaarde5, {          //link toevoegen aan vorig object
                name: 'Textx', size: 10, multihash: (hashwaarde6)   }) 

                    var { cid } = await ipfs.add(encoder.encode(param7), addOptions)
                    for await (const chunk7 of ipfs.cat(cid)) {
                    const hashwaarde7 = (cid.string)
                    const hashdirwaarde7 = await ipfs.object.patch.addLink(hashdirwaarde6, {          //link toevoegen aan vorig object
                    name: 'Texty', size: 10, multihash: (hashwaarde7)   }) 

                        var { cid } = await ipfs.add(encoder.encode(param8), addOptions)
                        for await (const chunk8 of ipfs.cat(cid)) {
                        const hashwaarde8 = (cid.string)
                        const hashdirwaarde8 = await ipfs.object.patch.addLink(hashdirwaarde7, {          //link toevoegen aan vorig object
                        name: 'ImageType', size: 10, multihash: (hashwaarde8)   }) 

                            var { cid } = await ipfs.add(encoder.encode(param9), addOptions)
                            for await (const chunk9 of ipfs.cat(cid)) {
                            const hashwaarde9 = (cid.string)
                            const hashdirwaarde9 = await ipfs.object.patch.addLink(hashdirwaarde8, {          //link toevoegen aan vorig object
                            name: 'Imagex', size: 10, multihash: (hashwaarde9)   }) 

                                var { cid } = await ipfs.add(encoder.encode(param10), addOptions)
                                for await (const chunk10 of ipfs.cat(cid)) {
                                const hashwaarde10 = (cid.string)
                                const hashdirwaarde10 = await ipfs.object.patch.addLink(hashdirwaarde9, {          //link toevoegen aan vorig object
                                name: 'Imagey', size: 10, multihash: (hashwaarde10)   }) 

                            //        var { cid } = await ipfs.add(encoder.encode(param11), addOptions)
                              //      for await (const chunk11 of ipfs.cat(cid)) {
                                //    const hashwaarde11 = (cid.string)
                                  //  const hashdirwaarde11 = await ipfs.object.patch.addLink(hashdirwaarde10, {          //link toevoegen aan vorig object
                                    //name: 'Bitmap1', size: 10, multihash: (hashwaarde11)   }) 

                                        var { cid } = await ipfs.add(encoder.encode(param12), addOptions)
                                        for await (const chunk12 of ipfs.cat(cid)) {
                                        const hashwaarde12 = (cid.string)
                                        const hashdirwaarde12 = await ipfs.object.patch.addLink(hashdirwaarde10, {          //link toevoegen aan vorig object
                                        name: 'Bitmap2', size: 10, multihash: (hashwaarde12)   }) 

                                            var { cid } = await ipfs.add(encoder.encode(param13), addOptions)
                                            for await (const chunk13 of ipfs.cat(cid)) {
                                            const hashwaarde13 = (cid.string)
                                            const hashdirwaarde13 = await ipfs.object.patch.addLink(hashdirwaarde12, {          //link toevoegen aan vorig object
                                            name: 'Bitmap3', size: 10, multihash: (hashwaarde13)   }) 

                                                var { cid } = await ipfs.add(encoder.encode(param14), addOptions)
                                                for await (const chunk14 of ipfs.cat(cid)) {
                                                const hashwaarde14 = (cid.string)
                                                const hashdirwaarde14 = await ipfs.object.patch.addLink(hashdirwaarde13, {          //link toevoegen aan vorig object
                                                name: 'Bitmap4', size: 10, multihash: (hashwaarde14)   }) 

                                                    var { cid } = await ipfs.add(encoder.encode(param15), addOptions)
                                                    for await (const chunk15 of ipfs.cat(cid)) {
                                                    const hashwaarde15 = (cid.string)
                                                    const hashdirwaarde15 = await ipfs.object.patch.addLink(hashdirwaarde14, {          //link toevoegen aan vorig object
                                                    name: 'BitmapTime', size: 10, multihash: (hashwaarde15)   }) 

                                                        var { cid } = await ipfs.add(encoder.encode(param16), addOptions)
                                                        for await (const chunk16 of ipfs.cat(cid)) {
                                                        const hashwaarde16 = (cid.string)
                                                        const hashdirwaarde16 = await ipfs.object.patch.addLink(hashdirwaarde15, {          //link toevoegen aan vorig object
                                                        name: 'VideoStart', size: 10, multihash: (hashwaarde16)   }) 

                                                            var { cid } = await ipfs.add(encoder.encode(param17), addOptions)
                                                            for await (const chunk17 of ipfs.cat(cid)) {
                                                            const hashwaarde17 = (cid.string)
                                                            const hashdirwaarde17 = await ipfs.object.patch.addLink(hashdirwaarde16, {          //link toevoegen aan vorig object
                                                            name: 'VideoStop', size: 10, multihash: (hashwaarde17)   }) 
//_________________hash of blob voor Bitmap opslaan_______________
                                                                var Bitmap1 = param11.slice(0,4)
                                                                if (Bitmap1 == 'blob')  {
                                                                  log('Bitmap1 hoeft niet vernieuwd')
                                                                  document.getElementById('form-read-P8param0').value=hashdirwaarde17.string //Als er blob in param11 zit is er dus geen nieuw plaatje op te slaan (remove is dan dus ook al over geslagen) dan ook de stap van add overslaan
                                                                    } else { var Bitmap1 = param11 }
                                                                      var { cid } = await ipfs.add((Bitmap1), addOptions)
                                                                      for await (const chunk19 of ipfs.cat(cid)) {
                                                                      const Bitmap1H = '/ipfs/'+Bitmap1
                                                                      log('Bitmap1 heeft een nieuw plaatje '+Bitmap1H)
                                                                      document.getElementById('form-read-P8param11').value='blob'
                                                    //hier moet nog echt een blob gemaakt worden en in param11 gestopt
                                                                var { cid } = await ipfs.add(encoder.encode(Bitmap1H), addOptions)
                                                                for await (const chunk18 of ipfs.cat(cid)) {
                                                                const hashwaarde18 = (cid.string)
                                                                const hashdirwaarde18 = await ipfs.object.patch.addLink(hashdirwaarde17, {          //link toevoegen aan vorig object
                                                                name: 'Bitmap1H', size: 10, multihash: (hashwaarde18)   }) 
                                                                    var { cid } = await ipfs.add(encoder.encode(param11), addOptions)
                                                                    for await (const chunk20 of ipfs.cat(cid)) {
                                                                    const hashwaarde20 = (cid.string)
                                                                    const hashdirwaarde20 = await ipfs.object.patch.addLink(hashdirwaarde18, {          //link toevoegen aan vorig object
                                                                    name: 'Bitmap1', size: 10, multihash: (hashwaarde20)   }) 
                                                                document.getElementById('form-read-P8param0').value=hashdirwaarde20.string
                                                                }}}
                                                            }
                                                        }
                                                    }
                                                }
                                            //}//chunk11
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const hashdirwaarde18 =(getValue('form-read-P8param0'))






        //1jpg 2 3 4 opslaan met .files-api in MFS in bestandsdirectory(dir=DCode) 
        //plaatsen in de bestandsdirectory dir=Sheetcode (eerst aanmaken als hij er nog niet is) De hash van deze root kan gebruikt worden om in andere websites te linken (met een pad) naar plaatjes via een gateway






        updateTree(ipfs)





        //de directe (nested) hash ophalen en link weghalen
        const dir2 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/'+param2+'/')
        const dir2hash = (dir2.cid)
        const dir2hashleeg = await ipfs.object.patch.rmLink(dir2hash, {       
          name: param1   })
        const dir2hashleeghash = (dir2hashleeg.string)
        const dir3 = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/')
        const dir3hash = (dir3.cid)
        const dir3hashleeg = await ipfs.object.patch.rmLink(dir3hash, {       
          name: param2   })
        const dir3hashleeghash = (dir3hashleeg.string)


        // 2x het bestaande IPFS-object updaten met nieuwe link
        const updated_roothash2 = await ipfs.object.patch.addLink(dir2hashleeghash, {    //DCode er opnieuw bij linken 
          name: param1,
          size: 10,
          multihash: (hashdirwaarde18)   })

        const updated_roothash = await ipfs.object.patch.addLink(dir3hashleeghash, {    //SheetCode er opnieuw bij linken 
          name: param2,
          size: 10,
          multihash: (updated_roothash2)   })

        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur
        log('bestandstructuur opgeslagen in ipfs.object')
        const ContentVariabelenhashN = (updated_roothash.string)
        log('nieuwe link voor ContentVariabelen '+ContentVariabelenhashN+' met /'+param2+'/'+param1+'/Bitmap1  erachter is het pad naar de opgeslagen waarden')
        await ipfs.files.write('/ContentVariabelen.txt', Buffer.from(ContentVariabelenhashN), {create: true, parents: true})
        updateTree(ipfs)





        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P8 ContentVariabelenWegschrijf2')
          }
        exportRoot.play();
        log ('________P8 ContentVariabelenWegschrijf2 klaar__________')
    }


//Interestnet ____________________________ContentVariabelenWegschrijf3 (alleen de TextNew waarden, dus wat is ingetypt en niet gepublished)___________________________________


    async function opdrachtP9(param1,param2,param3,param4,param5,param6,param7,param8) {
      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new('unixfs-dir')        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (hashdir)  })
        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/ContentVariabelenhash.txt')
              .then((stream1) =>  {  const orbit_ContentVariabelenhash = (stream1.toString('utf8'))      
                              log('Orbit ContentVariabelenhash: '+orbit_ContentVariabelenhash)
                              document.getElementById('form-read-P9param0').value=orbit_ContentVariabelenhash    })
              .catch(error => {   const orbit_ContentVariabelenhash = (updated_leeghash.toString())      
                                  log(error+' dus gebruik een leeg object: '+orbit_ContentVariabelenhash) 
                                  document.getElementById('form-read-P9param0').value=orbit_ContentVariabelenhash })

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const orbit_ContentVariabelenhash =(getValue('form-read-P9param0'))
        console.log('orbit_ContentVariabelenhash opgehaald: '+orbit_ContentVariabelenhash)


        //bepalen of het een hash met extra x (aanpasbaar of bezet) is 
        var orbit_ContentVariabelenhashV = orbit_ContentVariabelenhash        
        var n = 2
        var nn = 0
         while (n==2) { if (orbit_ContentVariabelenhashV.length == 46 && nn<=0)  {
              var blokeer = orbit_ContentVariabelenhash+'x'
              await ipfs.files.write('/orbit/ContentVariabelenhash.txt', Buffer.from(blokeer), {create: true, parents: true})      //met extra teken erachter wegschrijven
              blokeer = 0    
              console.log('hash is beschikbaar om aan te passen, extra teken wordt erachter gezet')
              n = 1
              nn = nn+1
            } else if (orbit_ContentVariabelenhashV.length == 47 && nn>=4) {
              console.log('hash wordt al door iemand aangepast dus even wachten')
              nn = nn-3
            } else if (nn<=4) {
                nn = nn+1
                console.log(nn)
            } else {
                var orbit_ContentVariabelenhashV = (updated_leeghash.toString())
                console.log('nieuwe orbit_ContentVariabelenhash.txt aanmaken')
                n = 1
            }
         }
      

        //de directe (nested) hash ophalen    //param2=SheetCode en param1=DCode param3=LocationCode
        const Wegschrijf1 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhashV+'/'+param2+'/'+param1+'/')
        const Wegschrijf1hash = (Wegschrijf1.hash)
        log('hash waar de links uit worden verwijderd: '+Wegschrijf1hash)
        
        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur--> zie onderin




        //bestaande links verwijderen
        const hashdirwaarde4leeg = await ipfs.object.patch.rmLink(Wegschrijf1hash, {          
          name: 'Text1new'   })
        const hashdirwaarde5leeg = await ipfs.object.patch.rmLink(hashdirwaarde4leeg, {     
          name: 'Text2new'   })
        const hashdirwaarde6leeg = await ipfs.object.patch.rmLink(hashdirwaarde5leeg, {        
          name: 'Text3new'   })
        const hashdirwaarde7leeg = await ipfs.object.patch.rmLink(hashdirwaarde6leeg, {       
          name: 'Text4new'   })
        const hashdirwaarde8leeg = await ipfs.object.patch.rmLink(hashdirwaarde7leeg, {          
          name: 'Titlenew'   })

        log('hash waaraan wordt gelinkt: '+hashdirwaarde8leeg.toString())
        const Wegschrijf1hashleeg = hashdirwaarde8leeg

        //waarden opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
        const addOptions = {  pin: true };

        const waarde4 = await ipfs.add(param4, addOptions)
        const hashwaarde4 = (waarde4[0].path.toString())
        const hashdirwaarde4 = await ipfs.object.patch.addLink(Wegschrijf1hashleeg, {          //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: 'Text1new', size: 10, multihash: (hashwaarde4)   })

        const waarde5 = await ipfs.add(param5, addOptions)
        const hashwaarde5 = (waarde5[0].path.toString())
        const hashdirwaarde5 = await ipfs.object.patch.addLink(hashdirwaarde4, {          //link toevoegen aan vorig object
          name: 'Text2new', size: 10, multihash: (hashwaarde5)   })

        const waarde6 = await ipfs.add(param6, addOptions)
        const hashwaarde6 = (waarde6[0].path.toString())
        const hashdirwaarde6 = await ipfs.object.patch.addLink(hashdirwaarde5, {          //link toevoegen aan vorig object
          name: 'Text3new', size: 10, multihash: (hashwaarde6)   })

        const waarde7 = await ipfs.add(param7, addOptions)
        const hashwaarde7 = (waarde7[0].path.toString())
        const hashdirwaarde7 = await ipfs.object.patch.addLink(hashdirwaarde6, {          //link toevoegen aan vorig object
          name: 'Text4new', size: 10, multihash: (hashwaarde7)   })

        const waarde8 = await ipfs.add(param8, addOptions)
        const hashwaarde8 = (waarde8[0].path.toString())
        const hashdirwaarde8 = await ipfs.object.patch.addLink(hashdirwaarde7, {          //link toevoegen aan vorig object
          name: 'Titlenew', size: 10, multihash: (hashwaarde8)   })






        const root = ("/ContentVariabelen/"+param2+"/"+param1)  //param2=SheetCode en param1=DCode param3=LocationCode
        const  padP8param4 =(root+"/Text1new")
        const  padP8param5 =(root+"/Text2new")
        const  padP8param6 =(root+"/Text3new")
        const  padP8param7 =(root+"/Text4new")
        const  padP8param8 =(root+"/Titlenew")



        await ipfs.files.mkdir(root, {create: true, parents: true})
        await ipfs.files.write(padP8param4, Buffer.from(param4), {create: true, parents: true})
        await ipfs.files.write(padP8param5, Buffer.from(param5), {create: true, parents: true})
        await ipfs.files.write(padP8param6, Buffer.from(param6), {create: true, parents: true})
        await ipfs.files.write(padP8param7, Buffer.from(param7), {create: true, parents: true})
        await ipfs.files.write(padP8param8, Buffer.from(param8), {create: true, parents: true})
        updateTree(ipfs)


        //de hash opzoeken van de files hierboven aangemaakt
        const sheetcodes_dirhash = await ipfs.files.stat('/ContentVariabelen/'+param2) //als link met de naam van de sheetcode  toe te voegen aan IPFS-object 
        const dcodes_dirhash = await ipfs.files.stat('/ContentVariabelen/'+param2+'/'+param1) //deze link met naam van de dcode wordt straks om toe te voegen aan het IPFS-object
        log('MFS-bestandstructuur is aangemaakt, MFS-hash van de nodedir met de sheetcodes is: '+(sheetcodes_dirhash.hash))






        //de directe (nested) hash ophalen en link weghalen
        const dir2 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhashV+'/'+param2+'/')
        const dir2hash = (dir2.hash)
        const dir2hashleeg = await ipfs.object.patch.rmLink(dir2hash, {       
          name: param1   })
        const dir2hashleeghash = (dir2hashleeg.toString())

        const dir3 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhashV+'/')
        const dir3hash = (dir3.hash)
        const dir3hashleeg = await ipfs.object.patch.rmLink(dir3hash, {       
          name: param2   })
        const dir3hashleeghash = (dir3hashleeg.toString())


        // 2x het bestaande IPFS-object updaten met nieuwe link
        const updated_roothash2 = await ipfs.object.patch.addLink(dir2hashleeghash, {    //DCode er opnieuw bij linken 
          name: param1,
          size: 10,
          multihash: (hashdirwaarde8)   })

        const updated_roothash = await ipfs.object.patch.addLink(dir3hashleeghash, {    //SheetCode er opnieuw bij linken 
          name: param2,
          size: 10,
          multihash: (updated_roothash2)   })


        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur
        log('bestandstructuur opgeslagen in ipfs.object')
        const orbit_ContentVariabelenhashN = (updated_roothash.toString())
        log('nieuwe link voor orbit_ContentVariabelen '+orbit_ContentVariabelenhashN+' met /'+param2+'/'+param1+'/Bitmat1  erachter is het pad naar de opgeslagen waarden')

        await ipfs.files.rm('/orbit/ContentVariabelenhash.txt')  //eerst weggooien anders werkt het overschrijven met een kortere waarde niet (hij doet als het ware 'insert')
          .catch(error => log(error))
        await ipfs.files.write('/orbit/ContentVariabelenhash.txt', Buffer.from(orbit_ContentVariabelenhashN), {create: true, parents: true})
        updateTree(ipfs)



        //waardes zo ophalen:
        const testhash = (updated_roothash.toString()+'/'+param2+'/'+param1+'/Bitmap1')
        const testwaarde = await ipfs.cat(testhash)
        log('test ophalen Bitmap1= '+testwaarde.toString('utf8'))



        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P9 ContentVariabelenWegschrijf3')
          }
        exportRoot.play();
        log ('________P9 ContentVariabelenWegschrijf3 klaar__________')
    }

//Interestnet ____________________________ContentVariabelenOphaal___________________________________

    async function opdrachtP10(param1,param2,param3) {

            const encoder = new TextEncoder()
            const decoder = new TextDecoder()

    try {

        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new({template: 'unixfs-dir'})        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (hashdir)  })
        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
        await ipfs.files.mv('/ContentVariabelen.txt', '/ContentVariabelen2.txt')
        await ipfs.files.mv('/ContentVariabelen2.txt', '/ContentVariabelen.txt')
        .then(() =>  { document.getElementById('form-read-P10param0').value=1 })
        .catch(error => { log('er is geen /ContentVariabelen.txt')
          document.getElementById('form-read-P10param0').value=0  })

        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const f =(getValue('form-read-P10param0'))
 
        if (f == 0) { const ContentVariabelenL = (updated_leeghash.toString())      
                      log('error dus gebruik een leeg object: '+ContentVariabelenL) 
                      document.getElementById('form-read-P10param0').value=ContentVariabelenL }
        if (f == 1) {for await (const chunk of ipfs.files.read('/ContentVariabelen.txt')) {
                               const ContentVariabelenL = (decoder.decode(chunk))
                               log("ContentVariabelen.txt opgehaald: "+ContentVariabelenL)
                               document.getElementById('form-read-P10param0').value=ContentVariabelenL } }
                                
        // constant uit de functie brengen via de html 
        var getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const ContentVariabelenL =(getValue('form-read-P10param0'))
        
        //als er een x achter staat (wordt net op dat moment dus aangepast) die eraf knippen
        const ContentVariabelen = ContentVariabelenL.slice(0,46)
        console.log('ContentVariabelen opgehaald en geknipt: '+ContentVariabelen)
      

        //de directe (nested) hash ophalen    //param2=SheetCode en param1=DCode param3=LocationCode
        const ophaal = await ipfs.files.stat('/ipfs/'+ContentVariabelen+'/'+param2+'/'+param1+'/')
        const ophaalhash = (ophaal.cid)
        log('hash waar de links uit worden verwijderd: '+ophaalhash)
        

        //waardes zo ophalen:
        const waarde1hash = (ophaalhash+'/Teleport')
        var {chunkx} = await ipfs.cat(waarde1hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde1hash)) {
        const waarde1 = decoder.decode(chunk)
        document.getElementById('dom-targetA10').value=waarde1
        log('Teleport= '+waarde1)  }

        const waarde2hash = (ophaalhash+'/Title')
        var {chunkx} = await ipfs.cat(waarde2hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde2hash)) {
        const waarde2 = decoder.decode(chunk)
        document.getElementById('dom-targetA11').value=waarde2
        log('Title= '+waarde2)  }

        const waarde3hash = (ophaalhash+'/Titlenew')
        var {chunkx} = await ipfs.cat(waarde3hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde3hash)) {
        const waarde3 = decoder.decode(chunk)
        document.getElementById('dom-targetA12').value=waarde3
        log('Titlenew= '+waarde3)  }

        const waarde4hash = (ophaalhash+'/Text1')
        var {chunkx} = await ipfs.cat(waarde4hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde4hash)) {
        const waarde4 = decoder.decode(chunk)
        document.getElementById('dom-targetA13').value=waarde4
        log('Text1= '+waarde4)  }

        const waarde5hash = (ophaalhash+'/Text1new')
        var {chunkx} = await ipfs.cat(waarde5hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde5hash)) {
        const waarde5 = decoder.decode(chunk)
        document.getElementById('dom-targetA14').value=waarde5
        log('Text1new= '+waarde5)  }

        const waarde6hash = (ophaalhash+'/Text2')
        var {chunkx} = await ipfs.cat(waarde6hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde6hash)) {
        const waarde6 = decoder.decode(chunk)
        document.getElementById('dom-targetA15').value=waarde6
        log('Text2= '+waarde6)  }

        const waarde7hash = (ophaalhash+'/Text2new')
        var {chunkx} = await ipfs.cat(waarde7hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde7hash)) {
        const waarde7 = decoder.decode(chunk)
        document.getElementById('dom-targetA16').value=waarde7
        log('Text2new= '+waarde7)  }

        const waarde8hash = (ophaalhash+'/Text3')
        var {chunkx} = await ipfs.cat(waarde8hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde8hash)) {
        const waarde8 = decoder.decode(chunk)
        document.getElementById('dom-targetA17').value=waarde8
        log('Text3= '+waarde8)  }

        const waarde9hash = (ophaalhash+'/Text3new')
        var {chunkx} = await ipfs.cat(waarde9hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde9hash)) {
        const waarde9 = decoder.decode(chunk)
        document.getElementById('dom-targetA18').value=waarde9
        log('Text3new= '+waarde9)  }

        const waarde10hash = (ophaalhash+'/Text4')
        var {chunkx} = await ipfs.cat(waarde10hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde10hash)) {
        const waarde10 = decoder.decode(chunk)
        document.getElementById('dom-targetA19').value=waarde10
        log('Text4= '+waarde10)  }

        const waarde11hash = (ophaalhash+'/Text4new')
        var {chunkx} = await ipfs.cat(waarde11hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde11hash)) {
        const waarde11 = decoder.decode(chunk)
        document.getElementById('dom-targetA20').value=waarde11
        log('Text4new= '+waarde11)  }

        const waarde12hash = (ophaalhash+'/TextType')
        var {chunkx} = await ipfs.cat(waarde12hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde12hash)) {
        const waarde12 = decoder.decode(chunk)
        document.getElementById('dom-targetA21').value=waarde12
        log('TextType= '+waarde12)  }

        const waarde13hash = (ophaalhash+'/TextTime')
        var {chunkx} = await ipfs.cat(waarde13hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde13hash)) {
        const waarde13 = decoder.decode(chunk)
        document.getElementById('dom-targetA22').value=waarde13
        log('TextTime= '+waarde13)  }

        const waarde14hash = (ophaalhash+'/Textx')
        var {chunkx} = await ipfs.cat(waarde14hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde14hash)) {
        const waarde14 = decoder.decode(chunk)
        document.getElementById('dom-targetA23').value=waarde14
        log('Textx= '+waarde14)  }

        const waarde15hash = (ophaalhash+'/Texty')
        var {chunkx} = await ipfs.cat(waarde15hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde15hash)) {
        const waarde15 = decoder.decode(chunk)
        document.getElementById('dom-targetA24').value=waarde15
        log('Texty= '+waarde15)  }

        const waarde16hash = (ophaalhash+'/ImageType')
        var {chunkx} = await ipfs.cat(waarde16hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde16hash)) {
        const waarde16 = decoder.decode(chunk)
        document.getElementById('dom-targetA25').value=waarde16
        log('ImageType= '+waarde16)  }

        const waarde17hash = (ophaalhash+'/Imagex')
        var {chunkx} = await ipfs.cat(waarde17hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde17hash)) {
        const waarde17 = decoder.decode(chunk)
        document.getElementById('dom-targetA26').value=waarde17
        log('Imagex= '+waarde17)  }

        const waarde18hash = (ophaalhash+'/Imagey')
        var {chunkx} = await ipfs.cat(waarde18hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde18hash)) {
        const waarde18 = decoder.decode(chunk)
        document.getElementById('dom-targetA27').value=waarde18
        log('Imagey= '+waarde18)  }

        const waarde19hash = (ophaalhash+'/Bitmap1H')
        var {chunkx} = await ipfs.cat(waarde19hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde19hash)) {
        const waarde19 = decoder.decode(chunk)
        var Bitmap1H = waarde19
        log('Bitmap1= blob wordt aangemaakt van '+Bitmap1H)  }

        const waarde20hash = (ophaalhash+'/Bitmap2')
        var {chunkx} = await ipfs.cat(waarde20hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde20hash)) {
        const waarde20 = decoder.decode(chunk)
        document.getElementById('dom-targetA29').value=waarde20
        log('Bitmap2= '+waarde20)  }

        const waarde21hash = (ophaalhash+'/Bitmap3')
        var {chunkx} = await ipfs.cat(waarde21hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde21hash)) {
        const waarde21 = decoder.decode(chunk)
        document.getElementById('dom-targetA30').value=waarde21
        log('Bitmap3= '+waarde21)  }

        const waarde22hash = (ophaalhash+'/Bitmap4')
        var {chunkx} = await ipfs.cat(waarde22hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde22hash)) {
        const waarde22 = decoder.decode(chunk)
        document.getElementById('dom-targetA31').value=waarde22
        log('Bitmap4= '+waarde22)  }

        const waarde23hash = (ophaalhash+'/BitmapTime')
        var {chunkx} = await ipfs.cat(waarde23hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde23hash)) {
        const waarde23 = decoder.decode(chunk)
        document.getElementById('dom-targetA32').value=waarde23
        log('BitmapTime= '+waarde23)  }

        const waarde24hash = (ophaalhash+'/VideoStart')
        var {chunkx} = await ipfs.cat(waarde24hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde24hash)) {
        const waarde24 = decoder.decode(chunk)
        document.getElementById('dom-targetA33').value=waarde24
        log('VideoStart= '+waarde24)  }

        const waarde25hash = (ophaalhash+'/VideoStop')
        var {chunkx} = await ipfs.cat(waarde25hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde25hash)) {
        const waarde25 = decoder.decode(chunk)        
        document.getElementById('dom-targetA34').value=waarde25
        log('VideoStop= '+waarde25)  }

        const waarde26hash = (ophaalhash+'/OriginalSheetCode')
        var {chunkx} = await ipfs.cat(waarde26hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde26hash)) {
        const waarde26 = decoder.decode(chunk)
        document.getElementById('dom-targetA35').value=waarde26
        log('OriginalSheetCode= '+waarde26)  }

        const waarde27hash = (ophaalhash+'/OriginalDCode')
        var {chunkx} = await ipfs.cat(waarde27hash) //dit deel lukt niet om weg te krijgen zonder dat er een error komt
        for await (const chunk of ipfs.cat(waarde27hash)) {
        const waarde27 = decoder.decode(chunk)
        document.getElementById('dom-targetA36').value=waarde27
        log('OriginalDCode= '+waarde27)  }


//SheetCodehash ophalen
//SheetCodehash/1jpg 2jpg 3jpg 4jpg blob aanmaken (alleen het eerste plaatje zit ook in P11 ContentTitleOphaal)
//blob naam terugsturen naar animate

//de rest ook

        document.getElementById('dom-targetA37').value='xx'
        document.getElementById('dom-targetA38').value='xx'
        document.getElementById('dom-targetA39').value='xx'


        //blob aanmaken voor Bitmap1-4 en blob-naam wegschrijven in dom-targets
        const addOptions = {  offset: 0, length: 0, timeout: 0 };
        for await (const chunk of ipfs.files.read(Bitmap1H, { addOptions }))    {
        console.info(decoder.decode(chunk))
        const data = bufferToArrayBuffer(chunk)
        const mimeType = 'image/jpeg'
        const file = new Blob([data], {
                    type: mimeType
                  })
        var Bitmap1B = URL.createObjectURL(file)
        document.getElementById('dom-targetA28').value=Bitmap1B
        log('blob nieuw aangemaakt: '+Bitmap1B)
        }
        


        } catch (err) {
            exportRoot.play();
            log ('inlezen deels mislukt: P10 ContentVariabelenOphaal')
          }
        exportRoot.play()
        log ('________P10 ContentVariabelenOphaal klaar__________')
    }

//Interestnet ____________________________ContentTitleOphaal___________________________________

    async function opdrachtP11(param1,param2,param3,param4,param5) {

      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new('unixfs-dir')        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (hashdir)  })
        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/ContentVariabelenhash.txt')
              .then((stream1) =>  {  const orbit_ContentVariabelenhash = (stream1.toString('utf8'))      
                              log('Orbit ContentVariabelenhash: '+orbit_ContentVariabelenhash)
                              document.getElementById('form-read-P11param0').value=orbit_ContentVariabelenhash    })
              .catch(error => {   const orbit_ContentVariabelenhash = (updated_leeghash.toString())      
                                  log(error+' dus gebruik een leeg object: '+orbit_ContentVariabelenhash) 
                                  document.getElementById('form-read-P11param0').value=orbit_ContentVariabelenhash })

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const orbit_ContentVariabelenhash =(getValue('form-read-P11param0'))
        console.log('orbit_ContentVariabelenhash opgehaald: '+orbit_ContentVariabelenhash)

      

        //de directe (nested) hash ophalen    //param1=SheetCode en param2=UDCode param3=RDCode param4=DDCode param5=LDCode 
        const pad1 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhash+'/'+param1+'/'+param2+'/Title')
        const pad1hash = (pad1.hash)
        log('hash waar de waarden worden opgehaald: '+pad1hash+'/'+param1+'/'+param2+'/Title')
        const waarde1 = await ipfs.cat(pad1hash)
        log('UTitle= '+waarde1.toString('utf8'))

        const pad2 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhash+'/'+param1+'/'+param3+'/Title')
        const pad2hash = (pad2.hash)
        const waarde2 = await ipfs.cat(pad2hash)
        log('RTitle= '+waarde2.toString('utf8'))

        const pad3 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhash+'/'+param1+'/'+param4+'/Title')
        const pad3hash = (pad3.hash)
        const waarde3 = await ipfs.cat(pad3hash)
        log('DTitle= '+waarde3.toString('utf8'))

        const pad4 = await ipfs.files.stat('/ipfs/'+orbit_ContentVariabelenhash+'/'+param1+'/'+param5+'/Title')
        const pad4hash = (pad4.hash)
        const waarde4 = await ipfs.cat(pad4hash)
        log('LTitle= '+waarde4.toString('utf8'))


//SheetCodehash ophalen
//SheetCodehash/1jpg blob aanmaken voor iedere richting (de hele reeks 1,2,3,4 zit in P10 contentvariabelenophaal)
//blob naam terugsturen naar animate

        } catch (err) {
            exportRoot.play();
            log ('inlezen deels mislukt: P11 ContentTitleOphaal')
          }
        exportRoot.play();
        log ('________P11 ContentTitleOphaal klaar__________')
    }


//Interestnet ____________________________DCodeWegschrijf___________________________________


    async function opdrachtP12(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16,param17) {
      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new('unixfs-dir')        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        //const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (hashdir)  })
        const updated_leeghash4 = await ipfs.object.patch.addLink(hashdir, { name: 'DCodes', size: 10, multihash: (hashdir) })

        //const updated_leeghash3 = await ipfs.object.patch.addLink(hashdir, { name: param3, size: 10, multihash: (hashdir)  })
        const updated_leeghash5 = await ipfs.object.patch.addLink(updated_leeghash4, { name: 'LocationCodes', size: 10, multihash: (hashdir) })

        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (updated_leeghash5) })
        //const updated_leeghash = await ipfs.object.patch.addLink(updated_leeghash0, { name: param1, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/DCodeVariabelenhash.txt')
              .then((stream1) =>  {  const orbit_DCodeVariabelenhash = (stream1.toString('utf8'))      
                              log('Orbit DCodeVariabelenhash: '+orbit_DCodeVariabelenhash)
                              document.getElementById('form-read-P12param0').value=orbit_DCodeVariabelenhash    })
              .catch(error => {   const orbit_DCodeVariabelenhash = (updated_leeghash.toString())      
                                  log(error+' dus gebruik een leeg object: '+orbit_DCodeVariabelenhash) 
                                  document.getElementById('form-read-P12param0').value=orbit_DCodeVariabelenhash })

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const orbit_DCodeVariabelenhash = (getValue('form-read-P12param0'))
        console.log('orbit_DCodeVariabelenhash opgehaald: '+orbit_DCodeVariabelenhash)

        //bepalen of het een hash met extra x (aanpasbaar of bezet) is 
        var orbit_DCodeVariabelenhashV = orbit_DCodeVariabelenhash        
        var n = 2
        var nn = 0
         while (n==2) { if (orbit_DCodeVariabelenhashV.length == 46 && nn<=0)  {
              var blokeer = orbit_DCodeVariabelenhash+'x'
              await ipfs.files.write('/orbit/DCodeVariabelenhash.txt', Buffer.from(blokeer), {create: true, parents: true})      //met extra teken erachter wegschrijven
              blokeer = 0    
              console.log('hash is beschikbaar om aan te passen, extra teken wordt erachter gezet')
              n = 1
              nn = nn+1
            } else if (orbit_DCodeVariabelenhashV.length == 47 && nn>=4) {
              console.log('hash wordt al door iemand aangepast dus even wachten')
              nn = nn-3
            } else if (nn<=4) {
                nn = nn+1
                console.log(nn)
            } else {
                var orbit_DCodeVariabelenhashV = (updated_leeghash.toString())
                console.log('nieuwe orbit_DCodeVariabelenhash.txt aanmaken')
                n = 1
            }
         }
        


        //de directe (nested) hash ophalen    //param1=SheetCode en param2=DCode param3=LocationCode
        const Wegschrijf1 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1+'/DCodes/')
        const Wegschrijf1hash = (Wegschrijf1.hash)
        const Wegschrijf2 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1+'/LocationCodes/')
        const Wegschrijf2hash = (Wegschrijf2.hash)
        log('hash waar de links uit worden verwijderd: '+Wegschrijf1hash+' en '+Wegschrijf2hash)
        
        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur--> zie onderin




        //bestaande links verwijderen
        const hashdirwaarde4leeg = await ipfs.object.patch.rmLink(Wegschrijf1hash, {         
          name: param2   })
        const hashdirwaarde44leeg = await ipfs.object.patch.rmLink(Wegschrijf2hash, {          
          name: param3   })

        log('hash waaraan wordt gelinkt: '+hashdirwaarde4leeg.toString()+' en '+hashdirwaarde44leeg.toString())
        const Wegschrijf1hashleeg = hashdirwaarde4leeg.toString()
        const Wegschrijf2hashleeg = hashdirwaarde44leeg.toString()


        //waarden opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
        const addOptions = {  pin: true };

        const waarde4 = await ipfs.add(param3, addOptions)  //de LocationCode waarde in DCode zetten
        const hashwaarde4 = (waarde4[0].path.toString())

        const hashdirwaarde4 = await ipfs.object.patch.addLink(Wegschrijf1hashleeg, {     //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: param2, size: 10, multihash: (hashwaarde4)   })
        const waarde44 = await ipfs.add(param2, addOptions)  //de LocationCode waarde in DCode zetten
        const hashwaarde44 = (waarde44[0].path.toString())

        const hashdirwaarde44 = await ipfs.object.patch.addLink(Wegschrijf2hashleeg, {     //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: param3, size: 10, multihash: (hashwaarde44)   })




        //de directe (nested) hash ophalen en link weghalen om te vervangen voor de nieuwe
        const dir2 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1)
        const dir2hash = (dir2.hash)
        const dir2hashleeg = await ipfs.object.patch.rmLink(dir2hash, {       
          name: 'DCodes'   })
        const dir2hashleeghash = (dir2hashleeg.toString())
        const dir2hashleegG = await ipfs.object.patch.rmLink(dir2hashleeghash, {       
          name: 'LocationCodes'   })
        const dir2hashleegGhash = (dir2hashleegG.toString())


        const dir3 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/')
        const dir3hash = (dir3.hash)
        const dir3hashleeg = await ipfs.object.patch.rmLink(dir3hash, {       
          name: param1   })
        const dir3hashleeghash = (dir3hashleeg.toString())

        // 2x het bestaande IPFS-object updaten met nieuwe link
        const updated_roothash1 = await ipfs.object.patch.addLink(dir2hashleegGhash, {    //DCode er opnieuw bij linken 
          name: 'DCodes',
          size: 10,
          multihash: (hashdirwaarde4)   })

          const updated_roothash2 = await ipfs.object.patch.addLink(updated_roothash1, {    //DCode er opnieuw bij linken 
          name: 'LocationCodes',
          size: 10,
          multihash: (hashdirwaarde44)   })

        const updated_roothash = await ipfs.object.patch.addLink(dir3hashleeghash, {    //SheetCode er opnieuw bij linken 
          name: param1,
          size: 10,
          multihash: (updated_roothash2)   })

        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur
        log('bestandstructuur opgeslagen in ipfs.object')
        const orbit_DCodeVariabelenhashN = (updated_roothash.toString())
        log('nieuwe link voor orbit_DCodeVariabelen '+orbit_DCodeVariabelenhashN+' met /'+param1+'/LocationCodes en DCodes'+param2+' erachter is het pad naar de opgeslagen waarden')

        await ipfs.files.rm('/orbit/DCodeVariabelenhash.txt')  //eerst weggooien anders werkt het overschrijven met een kortere waarde niet (hij doet als het ware 'insert')
          .catch(error => log(error))
        await ipfs.files.write('/orbit/DCodeVariabelenhash.txt', Buffer.from(orbit_DCodeVariabelenhashN), {create: true, parents: true})
        updateTree(ipfs)



        //waardes zo ophalen:
        const testhash = (updated_roothash.toString()+'/'+param1+'/DCodes/'+param2)
        const testwaarde = await ipfs.cat(testhash)
        log('bij DCode '+param2+' is de LocationCode = '+testwaarde.toString('utf8'))



        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P12 DCodeWegschrijf')
          }
        exportRoot.play();
        log ('________P12 DCodeWegschrijf klaar__________')
    }

//Interestnet ____________________________DCodeOphaal___________________________________


    async function opdrachtP12a(param1, param2) {
      try {

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/DCodeVariabelenhash.txt')
              .then((stream1) =>  {  const orbit_DCodeVariabelenhash = (stream1.toString('utf8'))      
                              log('Orbit DCodeVariabelenhash: '+orbit_DCodeVariabelenhash)
                              document.getElementById('form-read-P12aparam0').value=orbit_DCodeVariabelenhash    })
              .catch(error => {   const orbit_DCodeVariabelenhash = (updated_leeghash.toString())      
                                  log(error+' dus gebruik een leeg object: '+orbit_DCodeVariabelenhash) 
                                  document.getElementById('form-read-P12aparam0').value=orbit_DCodeVariabelenhash })

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const orbit_DCodeVariabelenhash = (getValue('form-read-P12aparam0'))
        console.log('orbit_DCodeVariabelenhash opgehaald: '+orbit_DCodeVariabelenhash)


        //waardes zo ophalen:
        const dehash = (orbit_DCodeVariabelenhash+'/'+param1+'/DCodes/'+param2)
        const dewaarde = await ipfs.cat(dehash)
        log('bij DCode '+param2+' is de LocationCode = '+dewaarde.toString('utf8'))
        document.getElementById('dom-targetA10').value=dewaarde.toString('utf8')

        //de rest ook
        document.getElementById('dom-targetA11').value='xx'
        document.getElementById('dom-targetA12').value='xx'
        document.getElementById('dom-targetA13').value='xx'
        document.getElementById('dom-targetA14').value='xx'
        document.getElementById('dom-targetA15').value='xx'
        document.getElementById('dom-targetA16').value='xx'
        document.getElementById('dom-targetA17').value='xx'
        document.getElementById('dom-targetA18').value='xx'
        document.getElementById('dom-targetA19').value='xx'
        document.getElementById('dom-targetA20').value='xx'
        document.getElementById('dom-targetA21').value='xx'
        document.getElementById('dom-targetA22').value='xx'
        document.getElementById('dom-targetA23').value='xx'
        document.getElementById('dom-targetA24').value='xx'
        document.getElementById('dom-targetA25').value='xx'
        document.getElementById('dom-targetA26').value='xx'
        document.getElementById('dom-targetA27').value='xx'
        document.getElementById('dom-targetA28').value='xx'
        document.getElementById('dom-targetA29').value='xx'
        document.getElementById('dom-targetA30').value='xx'
        document.getElementById('dom-targetA31').value='xx'
        document.getElementById('dom-targetA32').value='xx'
        document.getElementById('dom-targetA33').value='xx'
        document.getElementById('dom-targetA34').value='xx'
        document.getElementById('dom-targetA35').value='xx'
        document.getElementById('dom-targetA36').value='xx'
        document.getElementById('dom-targetA37').value='xx'
        document.getElementById('dom-targetA38').value='xx'
        document.getElementById('dom-targetA39').value='xx'


        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P12a DCodOphaal')
          }
        exportRoot.play();
        log ('___P12a DCodeOphaal klaar (LocationCode= uit Orbit-hash/SheetCode/Dcodes/DCode/xxx)_____')
    }

//Interestnet ____________________________DCodeVerwissel___________________________________


    async function opdrachtP13(param1,param2,param3,param4,param5,param6,param7,param8,param9,param10,param11,param12,param13,param14,param15,param16,param17) {
      try {
        //een leeg object aanmaken voor als file does not exist
        const hdir = await ipfs.object.new('unixfs-dir')        //(een leeg object.put wordt gezien als group en kan niet worden gebruikt daarom object.new)
        const hashdir = (hdir.toString())
        //const updated_leeghash2 = await ipfs.object.patch.addLink(hashdir, { name: param2, size: 10, multihash: (hashdir)  })
        const updated_leeghash4 = await ipfs.object.patch.addLink(hashdir, { name: 'DCodes', size: 10, multihash: (hashdir) })

        //const updated_leeghash3 = await ipfs.object.patch.addLink(hashdir, { name: param3, size: 10, multihash: (hashdir)  })
        const updated_leeghash5 = await ipfs.object.patch.addLink(updated_leeghash4, { name: 'LocationCodes', size: 10, multihash: (hashdir) })

        const updated_leeghash = await ipfs.object.patch.addLink(hashdir, { name: param1, size: 10, multihash: (updated_leeghash5) })
        //const updated_leeghash = await ipfs.object.patch.addLink(updated_leeghash0, { name: param1, size: 10, multihash: (updated_leeghash2) })

        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/DCodeVariabelenhash.txt')
              .then((stream1) =>  {  const orbit_DCodeVariabelenhash = (stream1.toString('utf8'))      
                              log('Orbit DCodeVariabelenhash: '+orbit_DCodeVariabelenhash)
                              document.getElementById('form-read-P13param0').value=orbit_DCodeVariabelenhash    })
              .catch(error => {   const orbit_DCodeVariabelenhash = (updated_leeghash.toString())      
                                  log(error+' dus gebruik een leeg object: '+orbit_DCodeVariabelenhash) 
                                  document.getElementById('form-read-P13param0').value=orbit_DCodeVariabelenhash })

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }
        const orbit_DCodeVariabelenhash = (getValue('form-read-P13param0'))
        console.log('orbit_DCodeVariabelenhash opgehaald: '+orbit_DCodeVariabelenhash)

        //bepalen of het een hash met extra x (aanpasbaar of bezet) is 
        var orbit_DCodeVariabelenhashV = orbit_DCodeVariabelenhash        
        var n = 2
        var nn = 0
         while (n==2) { if (orbit_DCodeVariabelenhashV.length == 46 && nn<=0)  {
              var blokeer = orbit_DCodeVariabelenhash+'x'
              await ipfs.files.write('/orbit/DCodeVariabelenhash.txt', Buffer.from(blokeer), {create: true, parents: true})      //met extra teken erachter wegschrijven
              blokeer = 0    
              console.log('hash is beschikbaar om aan te passen, extra teken wordt erachter gezet')
              n = 1
              nn = nn+1
            } else if (orbit_DCodeVariabelenhashV.length == 47 && nn>=4) {
              console.log('hash wordt al door iemand aangepast dus even wachten')
              nn = nn-3
            } else if (nn<=4) {
                nn = nn+1
                console.log(nn)
            } else {
                var orbit_DCodeVariabelenhashV = (updated_leeghash.toString())
                console.log('nieuwe orbit_DCodeVariabelenhash.txt aanmaken')
                n = 1
            }
         }
        


        //de directe (nested) hash ophalen    //param1=SheetCode en param2=DCodeA param3=LocationCodeA param4=DCodeB param5=LocationCodeB
        const Wegschrijf1 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1+'/DCodes/')
        const Wegschrijf1hash = (Wegschrijf1.hash)
        const Wegschrijf2 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1+'/LocationCodes/')
        const Wegschrijf2hash = (Wegschrijf2.hash)
        log('hash waar de links uit worden verwijderd: '+Wegschrijf1hash+' en '+Wegschrijf2hash)
        
        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur--> zie onderin




        //bestaande links verwijderen
        const hashdirwaarde4Aleeg = await ipfs.object.patch.rmLink(Wegschrijf1hash, {         
          name: param2   })
        const hashdirwaarde4leeg = await ipfs.object.patch.rmLink(hashdirwaarde4Aleeg, {         
          name: param4   })
        const hashdirwaarde44Aleeg = await ipfs.object.patch.rmLink(Wegschrijf2hash, {          
          name: param3   })
        const hashdirwaarde44leeg = await ipfs.object.patch.rmLink(hashdirwaarde44Aleeg, {          
          name: param5   })

        log('hash waaraan wordt gelinkt: '+hashdirwaarde4leeg.toString()+' en '+hashdirwaarde44leeg.toString())
        const Wegschrijf1hashleeg = hashdirwaarde4leeg.toString()
        const Wegschrijf2hashleeg = hashdirwaarde44leeg.toString()


        //waarden opslaan ipfs.add en linken door voorgaande object te updaten met object.patch.addLink
        const addOptions = {  pin: true };

        const waarde4A = await ipfs.add(param5, addOptions)  //de LocationCodeB waarde in DCodeA zetten (oftewel param5 in param2)
        const hashwaarde4A = (waarde4A[0].path.toString())

        const hashdirwaarde4A = await ipfs.object.patch.addLink(Wegschrijf1hashleeg, {     //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: param2, size: 10, multihash: (hashwaarde4A)   })

                const waarde4B = await ipfs.add(param3, addOptions)  //de LocationCodeA waarde in DCodeB zetten (oftewel param3 in param4)
        const hashwaarde4B = (waarde4B[0].path.toString())

        const hashdirwaarde4 = await ipfs.object.patch.addLink(hashdirwaarde4A, {     //link toevoegen aan vorig object
          name: param4, size: 10, multihash: (hashwaarde4B)   })
        

        const waarde44A = await ipfs.add(param2, addOptions)  //de DCodeA waarde in LocationCodeB zetten
        const hashwaarde44A = (waarde44A[0].path.toString())

        const hashdirwaarde44A = await ipfs.object.patch.addLink(Wegschrijf2hashleeg, {     //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: param5, size: 10, multihash: (hashwaarde44A)   })

        const waarde44B = await ipfs.add(param4, addOptions)  //de DCodeB waarde in LocationCodeA zetten
        const hashwaarde44B = (waarde44B[0].path.toString())

        const hashdirwaarde44 = await ipfs.object.patch.addLink(Wegschrijf2hashleeg, {     //link toevoegen aan hier net voor aangemaakte leeg/naamloos object
          name: param3, size: 10, multihash: (hashwaarde44B)   })




        //de directe (nested) hash ophalen en link weghalen om te vervangen voor de nieuwe
        const dir2 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/'+param1)
        const dir2hash = (dir2.hash)
        const dir2hashleeg = await ipfs.object.patch.rmLink(dir2hash, {       
          name: 'DCodes'   })
        const dir2hashleeghash = (dir2hashleeg.toString())
        const dir2hashleegG = await ipfs.object.patch.rmLink(dir2hashleeghash, {       
          name: 'LocationCodes'   })
        const dir2hashleegGhash = (dir2hashleegG.toString())


        const dir3 = await ipfs.files.stat('/ipfs/'+orbit_DCodeVariabelenhashV+'/')
        const dir3hash = (dir3.hash)
        const dir3hashleeg = await ipfs.object.patch.rmLink(dir3hash, {       
          name: param1   })
        const dir3hashleeghash = (dir3hashleeg.toString())

        // 2x het bestaande IPFS-object updaten met nieuwe link
        const updated_roothash1 = await ipfs.object.patch.addLink(dir2hashleegGhash, {    //DCode er opnieuw bij linken 
          name: 'DCodes',
          size: 10,
          multihash: (hashdirwaarde4)   })

          const updated_roothash2 = await ipfs.object.patch.addLink(updated_roothash1, {    //DCode er opnieuw bij linken 
          name: 'LocationCodes',
          size: 10,
          multihash: (hashdirwaarde44)   })

        const updated_roothash = await ipfs.object.patch.addLink(dir3hashleeghash, {    //SheetCode er opnieuw bij linken 
          name: param1,
          size: 10,
          multihash: (updated_roothash2)   })

        //orbit-hash vernieuwen    nu tijdelijk eerst via MFS-bestandstructuur
        log('bestandstructuur opgeslagen in ipfs.object')
        const orbit_DCodeVariabelenhashN = (updated_roothash.toString())
        log('nieuwe link voor orbit_DCodeVariabelen '+orbit_DCodeVariabelenhashN+' met /'+param1+'/LocationCodes en DCodes'+param2+' erachter is het pad naar de opgeslagen waarden')

        await ipfs.files.rm('/orbit/DCodeVariabelenhash.txt')  //eerst weggooien anders werkt het overschrijven met een kortere waarde niet (hij doet als het ware 'insert')
          .catch(error => log(error))
        await ipfs.files.write('/orbit/DCodeVariabelenhash.txt', Buffer.from(orbit_DCodeVariabelenhashN), {create: true, parents: true})
        updateTree(ipfs)



        //waardes zo ophalen:
        const testhash = (updated_roothash.toString()+'/'+param1+'/DCodes/'+param2)
        const testwaarde = await ipfs.cat(testhash)
        log('bij DCode '+param2+' is de LocationCode = '+testwaarde.toString('utf8'))



        } catch (err) {
            exportRoot.play();
            log ('mkdir en write is deels mislukt: P13 DCodeVerwissel')
          }
        exportRoot.play();
        log ('________P13 DCodeVerwissel klaar__________')
    }

//Interestnet ____________________________DCodeTitleOphaal___________________________________

    async function opdrachtP14(param1,param2,param3,param4,param5) {

      try {
        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/DCodeVariabelenhash.txt')
       const orbit_DCodeVariabelenhashx = (stream1.toString('utf8')) 
       const orbit_DCodeVariabelenhash = orbit_DCodeVariabelenhashx.slice(0,46)

       const stream2 = await ipfs.files.read('/orbit/ContentVariabelenhash.txt')
       const orbit_ContentVariabelenhashx = (stream2.toString('utf8')) 
       const orbit_ContentVariabelenhash = orbit_ContentVariabelenhashx.slice(0,46)

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }


        //param1=SheetCode en param2=ULocationCode param3=RLocationCode param4=DLocationCode param5=LLocationCode 
        const param2hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param2)
        const param2waarde =  await ipfs.cat(param2hash)
              .then((param2waarde) => {log('UDCode = '+param2waarde.toString('utf8'))
                    var UDCode = (param2waarde.toString('utf8'))
                    document.getElementById('form-read-P14param0').value=UDCode    } )
              .catch(error => { var UDCode = -1 
                document.getElementById('form-read-P14param0').value=UDCode
                log('UDCode = -1')} )

        var UDCode = await (getValue('form-read-P14param0')) //overbrengen

        const param3hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param3)
        const param3waarde = await ipfs.cat(param3hash)
              .then((param3waarde) => {log('RDCode = '+param3waarde.toString('utf8'))
                    var RDCode = (param3waarde.toString('utf8'))
                    document.getElementById('form-read-P14param2').value=RDCode   } )
              .catch(error => { var RDCode = -1  
                document.getElementById('form-read-P14param2').value=RDCode
                log('RDCode = -1')} )

        var RDCode = await (getValue('form-read-P14param2')) //overbrengen
        
        const param4hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param4)
        const param4waarde = await ipfs.cat(param4hash)
               .then((param4waarde) => {log('DDCode = '+param4waarde.toString('utf8'))
                    var DDCode = (param4waarde.toString('utf8'))
                    document.getElementById('form-read-P14param00').value=DDCode   } )
              .catch(error => { var DDCode = -1  
                document.getElementById('form-read-P14param00').value=DDCode
                log('DDCode = -1')} )

        var DDCode = await (getValue('form-read-P14param00')) //overbrengen

        const param5hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param5)
        const param5waarde = await ipfs.cat(param5hash)
              .then((param5waarde) => {log('LDCode = '+param5waarde.toString('utf8'))
                    var LDCode = (param5waarde.toString('utf8'))
                    document.getElementById('form-read-P14param22').value=LDCode   } )
              .catch(error => { var LDCode = -1  
                document.getElementById('form-read-P14param22').value=LDCode
                log('LDCode = -1')} )

        var LDCode = await (getValue('form-read-P14param22')) //overbrengen
        log('de DCodes zijn opgehaald vanuit '+param3hash)

//log(UDCode)
//log(RDCode)
//log(DDCode)
//log(LDCode)
//var RDCode = ('2341')
        const param6hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+UDCode+'/Title')
        const param6waarde = await ipfs.cat(param6hash)
              .then((param6waarde) => {log('UTitle = '+param6waarde.toString('utf8'))} )
              .catch(error => { var UTitle = -1
                log('UTitle = -1')} )

        const param7hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+RDCode+'/Title')
        const param7waarde = await ipfs.cat(param7hash)
              .then((param7waarde) => {log('RTitle = '+param7waarde.toString('utf8'))} )
              .catch(error => { var RTitle = -1  
                log('RTitle = -1')} )

        const param8hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+DDCode+'/Title')
        const param8waarde = await ipfs.cat(param8hash)
              .then((param8waarde) => {log('DTitle = '+param8waarde.toString('utf8'))} )
              .catch(error => { var DTitle = -1  
                log('DTitle = -1')} )

        const param9hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+LDCode+'/Title')
        const param9waarde = await ipfs.cat(param9hash)
              .then((param9waarde) => {log('LTitle = '+param9waarde.toString('utf8'))} )
              .catch(error => { var LTitle = -1  
                log('LTitle = -1')} )
        log('de titels zijn opgehaald vanuit '+param7hash)

        } catch (err) {
            exportRoot.play();
            log ('inlezen deels mislukt: P14 DCodeTitleOphaal')
          }
        exportRoot.play();
        log ('________P14 DCodeTitleOphaal klaar__________')
    }


//Interestnet ____________________________TPTitleOphaal___________________________________

    async function opdrachtP15(param1,param2,param3,param4,param5) {

      try {
        //orbit-hash ophalen   nu tijdelijk eerst via MFS, als does not exist dan het lege object nemen
       const stream1 = await ipfs.files.read('/orbit/DCodeVariabelenhash.txt')
       const orbit_DCodeVariabelenhashx = (stream1.toString('utf8')) 
       const orbit_DCodeVariabelenhash = orbit_DCodeVariabelenhashx.slice(0,46)

       const stream2 = await ipfs.files.read('/orbit/ContentVariabelenhash.txt')
       const orbit_ContentVariabelenhashx = (stream2.toString('utf8')) 
       const orbit_ContentVariabelenhash = orbit_ContentVariabelenhashx.slice(0,46)

        // constant uit de functie brengen via de html 
        const getValue = (id) => {
        const element = document.getElementById(id)
         return element.value.trim() }


        //param1=SheetCode en param2=UTPLocationCode param3=RTPLocationCode param4=DTPLocationCode param5=LTPLocationCode 
        const param2hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param2)
        const param2waarde =  await ipfs.cat(param2hash)
              .then((param2waarde) => {log('TP_UDCode = '+param2waarde.toString('utf8'))
                    var TP_UDCode = (param2waarde.toString('utf8'))
                    document.getElementById('form-read-P15param0').value=TP_UDCode    } )
              .catch(error => { var TP_UDCode = -1 
                document.getElementById('form-read-P15param0').value=TP_UDCode
                log('TP_UDCode = -1')} )

        var TP_UDCode = await (getValue('form-read-P15param0')) //overbrengen

        const param3hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param3)
        const param3waarde = await ipfs.cat(param3hash)
              .then((param3waarde) => {log('TP_RDCode = '+param3waarde.toString('utf8'))
                    var TP_RDCode = (param3waarde.toString('utf8'))
                    document.getElementById('form-read-P15param2').value=TP_RDCode   } )
              .catch(error => { var TP_RDCode = -1  
                document.getElementById('form-read-P15param2').value=TP_RDCode
                log('TP_RDCode = -1')} )

        var TP_RDCode = await (getValue('form-read-P15param2')) //overbrengen
        
        const param4hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param4)
        const param4waarde = await ipfs.cat(param4hash)
               .then((param4waarde) => {log('TP_DDCode = '+param4waarde.toString('utf8'))
                    var TP_DDCode = (param4waarde.toString('utf8'))
                    document.getElementById('form-read-P15param00').value=TP_DDCode   } )
              .catch(error => { var TP_DDCode = -1  
                document.getElementById('form-read-P15param00').value=TP_DDCode
                log('TP_DDCode = -1')} )

        var TP_DDCode = await (getValue('form-read-P15param00')) //overbrengen

        const param5hash = (orbit_DCodeVariabelenhash+'/'+param1+'/LocationCodes/'+param5)
        const param5waarde = await ipfs.cat(param5hash)
              .then((param5waarde) => {log('TP_LDCode = '+param5waarde.toString('utf8'))
                    var TP_LDCode = (param5waarde.toString('utf8'))
                    document.getElementById('form-read-P15param22').value=TP_LDCode   } )
              .catch(error => { var TP_LDCode = -1  
                document.getElementById('form-read-P15param22').value=TP_LDCode
                log('TP_LDCode = -1')} )

        var TP_LDCode = await (getValue('form-read-P15param22')) //overbrengen
        log('de TP_DCodes zijn opgehaald vanuit '+param3hash)

//log(TP_UDCode)
//log(TP_RDCode)
//log(TP_DDCode)
//log(TP_LDCode)
        const param6hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+TP_UDCode+'/Title')
        const param6waarde = await ipfs.cat(param6hash)
              .then((param6waarde) => {log('TP_UTitle = '+param6waarde.toString('utf8'))} )
              .catch(error => { var TP_UTitle = -1
                log('TP_UTitle = -1')} )

        const param7hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+TP_RDCode+'/Title')
        const param7waarde = await ipfs.cat(param7hash)
              .then((param7waarde) => {log('TP_RTitle = '+param7waarde.toString('utf8'))} )
              .catch(error => { var TP_RTitle = -1  
                log('TP_RTitle = -1')} )

        const param8hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+TP_DDCode+'/Title')
        const param8waarde = await ipfs.cat(param8hash)
              .then((param8waarde) => {log('TP_DTitle = '+param8waarde.toString('utf8'))} )
              .catch(error => { var TP_DTitle = -1  
                log('TP_DTitle = -1')} )

        const param9hash = (orbit_ContentVariabelenhash+'/'+param1+'/'+TP_LDCode+'/Title')
        const param9waarde = await ipfs.cat(param9hash)
              .then((param9waarde) => {log('TP_LTitle = '+param9waarde.toString('utf8'))} )
              .catch(error => { var TP_LTitle = -1  
                log('TP_LTitle = -1')} )
        log('de TPtitels zijn opgehaald vanuit '+param7hash)

        } catch (err) {
            exportRoot.play();
            log ('inlezen deels mislukt: P15 TPTitleOphaal')
          }
        exportRoot.play();
        log ('________P15 TPTitleOphaal klaar__________')
    }


 //Interestnet _______________________________________________________________

    async function opdrachtP(param1,param2,param3) {
      try {
        await ipfs.files.mkdir('/0000', {create: true, parents: true})
        await ipfs.files.mkdir('/11', {create: true, parents: true})
        await ipfs.files.mkdir('/12', {create: true, parents: true})
        await ipfs.files.mkdir('/13', {create: true, parents: true})
        await ipfs.files.mkdir('/14', {create: true, parents: true})
        await ipfs.files.mkdir('/21', {create: true, parents: true})
        await ipfs.files.mkdir('/22', {create: true, parents: true})
        await ipfs.files.mkdir('/23', {create: true, parents: true})
        await ipfs.files.mkdir('/24', {create: true, parents: true})
        await ipfs.files.mkdir('/31', {create: true, parents: true})

        await ipfs.files.write('/SheetCode.txt', Buffer.from('RP514'), {create: true, parents: true})
        await ipfs.files.write('/11/DCode.txt', Buffer.from('bafyreigsccjrxlioppkkzv27se4gxh2aygbxfnsobkaxxqiuni544uk66a'), {create: true, parents: true})
        await ipfs.files.write('/12/DCode.txt', Buffer.from('minimalwindow=1&check=1'), {create: true, parents: true})
        const stats = await ipfs.files.stat('/')
        log('structuur is aangemaakt, hash van de root is: '+(stats.cid))
          const stream = ipfs.files.readReadableStream('/11/DCode.txt')
          stream.on('data', (buf) => log(buf.toString('utf8')))

        updateTree(ipfs)

        } catch (err) {
            exportRoot.play();
            log ('mkdir is mislukt')
          }
        exportRoot.play();
        log ('mkdir is klaar')
    }





  })

