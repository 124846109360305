'use strict'

const modalScreen = document.getElementById('modal-screen')

modalScreen.onclick = (event) => {
  if (event.target === modalScreen) {
    hideForms()
  }
}

const forms = {
  mkdir: document.getElementById('form-mkdir'),
  mv: document.getElementById('form-mv'),
  cp: document.getElementById('form-cp'),
  rm: document.getElementById('form-rm'),
  stat: document.getElementById('form-stat'),
  read: document.getElementById('form-read'),
  readP1: document.getElementById('form-readP1'),
  readP2: document.getElementById('form-readP2'),
  readP3: document.getElementById('form-readP3'),
  readP4: document.getElementById('form-readP4'),
  readP5: document.getElementById('form-readP5'),
  readP6: document.getElementById('form-readP6'),
  readP7: document.getElementById('form-readP7'),
  readP8: document.getElementById('form-readP8'),
  readP9: document.getElementById('form-readP9'),
  readP10: document.getElementById('form-readP10'),
  readP11: document.getElementById('form-readP11'),
  readP12: document.getElementById('form-readP12'),
  readP13: document.getElementById('form-readP12a'),
  readP13: document.getElementById('form-readP13'),
  readP14: document.getElementById('form-readP14'),
  readP15: document.getElementById('form-readP15'),
  readP: document.getElementById('form-readP')
}

const getValue = (id) => {
  const element = document.getElementById(id)

  if (element.type === 'checkbox') {
    return Boolean(element.checked)
  }

  if (element.type === 'number') {
    const result = parseInt(element.value.trim())

    return isNaN(result) ? undefined : result
  }

  return element.value.trim()
}

const hideForms = () => {
  modalScreen.style.display = 'none'

  Object.values(forms)
    .forEach(form => {
      form.style.display = 'none'
    })
}


const showForm = (form) => {
  return (event) => {
    event.preventDefault()

    modalScreen.style.display = 'block'
    form.style.display = 'block'
  }
}


const mkdirForm = (onMkdir) => {
  const button = document.getElementById('button-mkdir')
  const submit = document.getElementById('button-form-mkdir-submit')

  button.onclick = showForm(forms.mkdir)
  submit.onclick = () => {
    hideForms()

    onMkdir(
      getValue('form-mkdir-path'),
      getValue('form-mkdir-parents'),
      getValue('form-mkdir-format'),
      getValue('form-mkdir-hashalg'),
      getValue('form-mkdir-flush')
    )
  }

  button.disabled = false
}

const mvForm = (onMv) => {
  const button = document.getElementById('button-mv')
  const submit = document.getElementById('button-form-mv-submit')

  button.onclick = showForm(forms.mv)
  submit.onclick = () => {
    hideForms()

    onMv(
      [getValue('form-mv-path')],
      getValue('form-mv-dest'),
      getValue('form-mv-parents'),
      getValue('form-mv-format'),
      getValue('form-mv-hashalg'),
      getValue('form-mv-flush')
    )
  }

  button.disabled = false
}

const cpForm = (onCp) => {
  const button = document.getElementById('button-cp')
  const submit = document.getElementById('button-form-cp-submit')

  button.onclick = showForm(forms.cp)
  submit.onclick = () => {
    hideForms()

    onCp(
      [getValue('form-cp-path')],
      getValue('form-cp-dest'),
      getValue('form-cp-parents'),
      getValue('form-cp-format'),
      getValue('form-cp-hashalg'),
      getValue('form-cp-flush')
    )
  }

  button.disabled = false
}

const rmForm = (onRm) => {
  const button = document.getElementById('button-rm')
  const submit = document.getElementById('button-form-rm-submit')

  button.onclick = showForm(forms.rm)
  submit.onclick = () => {
    hideForms()

    onRm(
      [getValue('form-rm-path')],
      getValue('form-rm-recursive')
    )
  }

  button.disabled = false
}

const statForm = (onStat) => {
  const button = document.getElementById('button-stat')
  const submit = document.getElementById('button-form-stat-submit')

  button.onclick = showForm(forms.stat)
  submit.onclick = () => {
    hideForms()

    onStat(
      getValue('form-stat-path'),
      getValue('form-stat-hash'),
      getValue('form-stat-size'),
      getValue('form-stat-withlocal')
    )
  }

  button.disabled = false
}

const readForm = (onRead) => {
  const button = document.getElementById('button-read')
  const submit = document.getElementById('button-form-read-submit')

  button.onclick = showForm(forms.read)
  
  submit.onclick = () => {
    hideForms()

    onRead(
      getValue('form-read-path'),
      getValue('form-read-offset'),
      getValue('form-read-length')
    )
  }

  button.disabled = false
}


//InterestNet P1__________ opdrachten auto activatie met benodigde parameters


  const activ_P1 = (onRead) => {
      const button = document.getElementById('button-P1')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P1 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P1param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P1param2'),
        getValue('form-read-P1param3')
      )}
  }
// InterestNet P2____________________________________________________________

  const activ_P2 = (onRead) => {
      const button = document.getElementById('button-P2')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P2 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P2param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P2param2'),
        getValue('form-read-P2param3'),
        getValue('form-read-P2param4'),
        getValue('form-read-P2param5'),
        getValue('form-read-P2param6'),
        getValue('form-read-P2param7'),
        getValue('form-read-P2param8'),
        getValue('form-read-P2param9')
      )}
  }
// InterestNet P3____________________________________________________________

  const activ_P3 = (onRead) => {
      const button = document.getElementById('button-P3')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren

      //onRead(      //eerste keer autom beginnen
      //  getValue('form-read-P2param1'), //peerID van P2 gebruikt  //niet meer nodig gaat nu direct met ident.id (index.js)   
      //  getValue('form-read-P3param2'),
      //  getValue('form-read-P3param3')  )

      button.onclick = () => {
        console.log('IPFS-P3 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P2param1'), //peerID van P2 gebruikt     
        getValue('form-read-P3param2'),
        getValue('form-read-P3param3')
      )}
  }




// InterestNet P4 _________________________________________________________

  const activ_P4 = (onRead) => {
      const button = document.getElementById('button-P4')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P4 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P4param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P4param2'),
        getValue('form-read-P4param3'),
        getValue('form-read-P4param4'),
        getValue('form-read-P4param5'),
        getValue('form-read-P4param6'),
        getValue('form-read-P4param7'),
        getValue('form-read-P4param8'),
        getValue('form-read-P4param9'),
        getValue('form-read-P4param10'),
        getValue('form-read-P4param11')
      )}
  }


//  InterestNet P5_________________________________________________________

  const activ_P5 = (onRead) => {
      const button = document.getElementById('button-P5')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P5 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P5param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P5param2'),
        getValue('form-read-P5param3')
      )}
  }


//  InterestNet P6_________________________________________________________

  const activ_P6 = (onRead) => {
      const button = document.getElementById('button-P6')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P6 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P6param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P6param2'),
        getValue('form-read-P6param3')
      )}
  }

//  InterestNet P7_________________________________________________________

  const activ_P7 = (onRead) => {        

      const button = document.getElementById('button-P7')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P7 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P7param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P7param2'),
        getValue('form-read-P7param3'),
        getValue('form-read-P7param4'),
        getValue('form-read-P7param5'),
        getValue('form-read-P7param6'),
        getValue('form-read-P7param7'),
        getValue('form-read-P7param8'),
        getValue('form-read-P7param9'),
        getValue('form-read-P7param10'),
        getValue('form-read-P7param11'),
        getValue('form-read-P7param12'),
        getValue('form-read-P7param13'),
        getValue('form-read-P7param14'),
        getValue('form-read-P7param15'),
        getValue('form-read-P7param16')
      )}
  }


//  InterestNet P8_________________________________________________________

  const activ_P8 = (onRead) => {
      const button = document.getElementById('button-P8')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P8 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P8param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P8param2'),
        getValue('form-read-P8param3'),
        getValue('form-read-P8param4'),
        getValue('form-read-P8param5'),
        getValue('form-read-P8param6'),
        getValue('form-read-P8param7'),
        getValue('form-read-P8param8'),
        getValue('form-read-P8param9'),
        getValue('form-read-P8param10'),
        getValue('form-read-P8param11'),
        getValue('form-read-P8param12'),
        getValue('form-read-P8param13'),
        getValue('form-read-P8param14'),
        getValue('form-read-P8param15'),
        getValue('form-read-P8param16'),
        getValue('form-read-P8param17')
      )}
  }

//  InterestNet P9_________________________________________________________

  const activ_P9 = (onRead) => {
      const button = document.getElementById('button-P9')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P9 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P9param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P9param2'),
        getValue('form-read-P9param3'),
        getValue('form-read-P9param4'),
        getValue('form-read-P9param5'),
        getValue('form-read-P9param6'),
        getValue('form-read-P9param7'),
        getValue('form-read-P9param8')
      )}
  }

//  InterestNet P10_________________________________________________________

  const activ_P10 = (onRead) => {
      const button = document.getElementById('button-P10')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P10 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P10param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P10param2'),
        getValue('form-read-P10param3')
      )}
  }

//  InterestNet P11_________________________________________________________

  const activ_P11 = (onRead) => {
      const button = document.getElementById('button-P11')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P11 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P11param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P11param2'),
        getValue('form-read-P11param3'),
        getValue('form-read-P11param4'),
        getValue('form-read-P11param5')
      )}
  }
//  InterestNet P12_________________________________________________________

  const activ_P12 = (onRead) => {
      const button = document.getElementById('button-P12')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren

      button.onclick = () => {
      console.log('IPFS-P12 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P12param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P12param2'),
        getValue('form-read-P12param3')
      )}
  }
//  InterestNet P12a_________________________________________________________

  const activ_P12a = (onRead) => {
      const button = document.getElementById('button-P12a')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      
      //onRead( //eerste keer autom beginnen
      //  getValue('form-read-P12aparam1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
      //  getValue('form-read-P12aparam2')  )

      button.onclick = () => {
      console.log('IPFS-P12a opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P12aparam1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P12aparam2')
      )}
  }
//  InterestNet P13_________________________________________________________

  const activ_P13 = (onRead) => {
      const button = document.getElementById('button-P13')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P13 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P13param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P13param2'),
        getValue('form-read-P13param3'),        
        getValue('form-read-P13param4'),
        getValue('form-read-P13param5')
      )}
  }

//  InterestNet P14_________________________________________________________

  const activ_P14 = (onRead) => {
      const button = document.getElementById('button-P14')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P14 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P14param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P14param2'),
        getValue('form-read-P14param3'),        
        getValue('form-read-P14param4'),
        getValue('form-read-P14param5')
      )}
  }

//  InterestNet P15_________________________________________________________

  const activ_P15 = (onRead) => {
      const button = document.getElementById('button-P15')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P15 opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-P15param1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-P15param2'),
        getValue('form-read-P15param3'),        
        getValue('form-read-P15param4'),
        getValue('form-read-P15param5')
      )}
  }

//  InterestNet P_________________________________________________________

  const activ_P = (onRead) => {
      const button = document.getElementById('button-P')
      //button.onclick = showForm(forms.mkdir) om een functie buiten deze functie te activeren
      button.onclick = () => {

      console.log('IPFS-P opdracht wordt uitgevoerd')
      onRead(
        getValue('form-read-Pparam1'),        //beschikbare (test- vanuit de standaard waarden in de html, bij een opdracht uit animate worden deze waarden eerst overschreven) parameters te gebruiken bij een InterestNet opdracht
        getValue('form-read-Pparam2'),
        getValue('form-read-Pparam3')
      )}
  }

module.exports = {
  mkdirForm,
  mvForm,
  rmForm,
  cpForm,
  statForm,
  readForm,
  hideForms,
  activ_P1,
  activ_P2,
  activ_P3,
  activ_P4,
  activ_P5,
  activ_P6,
  activ_P7,
  activ_P8,
  activ_P9,
  activ_P10,
  activ_P11,
  activ_P12,
  activ_P12a,
  activ_P13,
  activ_P14,
  activ_P15,
  activ_P
  
}
